import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { RayForm } from '../../components/common';
import { RayDataAction } from '../../actions/RayDataAction';
import { CompanyAction } from '../../actions/CompanyAction';
import { WarrantyProductType, WarrantyUPKitModel } from '../../config/RayCodes';
import { Translate as t } from '../../config/Translate';
import { InputBasicDateTime, InputBasicNumber } from '../common/Inputs';
import Loader from "react-loader-spinner";
import { CommonAction } from '../../actions';
import { UserType } from '../../config';

const RayDataAddUpdate = ({ GetAll, item, companyList, type, isHQ, data, callbackClose, showModal, Update, Create, clist }) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const [request, setRequest] = useState(false);
	const [val, setVal] = useState({});
	const [companies, setCompanies] = useState([]);
	const [info, setInfo] = useState({});
	const [models, setModels] = useState([]);
	const [delOk, setDelOk] = useState(false);

	useEffect(() => {
		if (data && Object.keys(data).length > 0)
			setInfo({
				_id: data._id,
				companyId: data.companyId,
				models: data.models,
				wartype: data.warrantyType,
				standards : data.standards
			});
	}, [data])

	useEffect(() => {
	}, [])

	useEffect(() => {
		if (request) {
			setRequest(false);
			callbackClose();
		}
	}, [item])

	const getModelsBig = () => {
		return [...new Set(clist.filter(x => x.type === 'PRODUCTOPTION' && x.category === "Extra-oral Imaging").map(x => x.group))].map(x => ({ code : x, title : x })) ;
	}

	const getModelsSmall = () => {
		return [...new Set(clist.filter(x => x.type === 'PRODUCTOPTION' && x.category !== "Extra-oral Imaging").map(x => x.group))].map(x => ({ code : x, title : x })) ;
	}

	useEffect(() => {
		if (info.wartype == "CT") {
			const filteredModel = user?.isMegagen ? getModelsBig().filter(x => x.code === "R2 Studio Q") : getModelsBig();
			setModels(filteredModel);
		} else if (info.wartype == "UP KIT") {
			setModels(WarrantyUPKitModel);
		} else if (info.wartype == "PRD") {
			const smallDevices = getModelsSmall();
			setModels(smallDevices);
		}
	}, [info])

	const setCompanySelect = () => {
		const companyId = user.companyId;
		const filtered = (companyId && companyId !== 'RAY00001') ? companyList.filter(x=> x.companyId === companyId || x.parentCompanyId === companyId) : companyList;

		const cList = [];
		filtered.map((c) => cList.push({ code: c.companyId, title: c.name }));
		setCompanies(cList);
	}

	useEffect(() => {
		if (companyList.length > 0)
			setCompanySelect();
	}, [companyList]);

	const war_controls = [{
		title: '', items: [
			{ type: 'select2', name: 'companyId', label: t('operator'), val: info.companyId, list: companies, isEditable: !info._id },			
			{ type: 'select2', name: 'wartype', label: t('warrantytype'), val: info.wartype, list: WarrantyProductType, isEditable: !info._id },
			{ type: 'select2', name: 'models', label: t('model'), val: info && info.models, isEditable: true, list: models, isMulti: true },
		]
	}];

	useEffect(() => {
		if (showModal)
			setVal({});
	}, [showModal]);

	const handleSubmit = () => {
		setRequest(true);
		
		let invalid = 0;

		info.standards.forEach(x => {
			const check = ['from', 'to', 'months'];
			check.forEach(y => {
				if (!x.hasOwnProperty(y) || !x[y])
					invalid++;
			})
		});

		if (invalid > 0) {
			alert('기준시작일, 기준종료일, 워런티(개월)은 반드시 입력해야 합니다.');
		} else {
			const category = isHQ ? 'HQ' : 'BRANCH';
			if (info._id)
				Update(type, info._id, { ...info, category });
			else
				Create(type, { ...info, category });
		}
	}

	const getTitle = () => {
		switch (type) {
			case "war":
				return (info && (info._id ? "Update " : "Add ")) + "Warranty Standards";
		}
	}

	const updateVal = (e) => {
		const { name, value } = e.target;
		setInfo({ ...info, [name]: value })
	}

	const addRow = (baseDate, type) => {
		const exists = !info.hasOwnProperty('standards') ? [] : info.standards.filter(x => x.baseDate === baseDate && x.type === type);
		const seq = (exists?.length > 0) ? Math.max(...exists.map(x => x.seq)) + 1 : 1;
		
		setInfo({ ...info, standards: [...info.standards || [], { baseDate, type, seq }] });
	}

	const removeRow = (baseDate, type, seq) => {
		setInfo({ ...info, standards: [...info.standards.filter(x => !(x.baseDate === baseDate && x.type === type && x.seq === seq))] });
	}

	const setStandards = (baseDate, type, seq, e) => {
		const standards = info.standards.find(x => x.baseDate === baseDate && x.type === type && x.seq === seq);
		setInfo({
			...info,
			standards: [
				...info.standards.filter(x => !(x.baseDate === baseDate && x.type === type && x.seq === seq)),
				{ ...standards, [e.target.name]: parseInt(e.target.value) }
			]
		});
	}

	const del = async () => {
		if(user){
			if(!delOk){
				setDelOk(true);
				return;
			}
			await CommonAction.GetRecord({
				data : {
					type : 'warranty',
					delitemid : {
						_id : data._id
					}
				}
			})
	
			const companyId = (UserType(user.companyId) === 'HQ') ? '' : user.companyId;
			GetAll("war", companyId);
	
			setDelOk(false); 
			callbackClose();
		}
	}

	console.log('data._id', data._id);

	return (
		<Modal show={showModal} onHide={() => { setDelOk(false); callbackClose(); }} size="xl">
			<Modal.Header>
				<h2 className="modal-title">{getTitle()}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				{type == "war" && <RayForm handleChangeData={updateVal} controls={war_controls} nocard={true} isForm={false} />}

				<div className='p-0 modal-body'>
					<div className='rayform section'>
						<div className='form-group basic'>						
							<div className='p-1'>
								<div style={{ display: 'inline-block', width: '49%', verticalAlign: 'top', marginRight:'14px' }}>
									<div className='d-flex justify-content-between'>
										<label>설치일 기준 제품 보증기간</label>
										<button
											className='btn btn-primary mt-0'
											style={{ fontSize: '13px',  height: '22px', padding: '0 5px', marginLeft: '10px' }}
											onClick={() => addRow('InstalledDate', 'Product')}
										>+{t('add')}</button>
									</div>
									<div className='w-100'>
									{
										info.standards?.filter(x => x.baseDate === "InstalledDate" && x.type === "Product").length > 0 ? info.standards.filter(x => x.baseDate === "InstalledDate" && x.type === "Product").sort((a, b) => { return a.seq - b.seq }).map((x,idx) => {
											return (
												<div key={`info_${idx}`} className='w-100 d-flex align-items-center'>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "from", onlyDate: true, val: x.from }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'Product', x.seq, e)} />
													</div>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "to", onlyDate: true, val: x.to }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'Product', x.seq, e)} />
													</div>
													<div style={{ width: '100px', flexGrow : 1 }}>
														<InputBasicNumber
															options={{ val: x.months || undefined, name: "months", placeholder: "Month(s)", positive: true }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'Product', x.seq, e)}
														/>
													</div>
													<div style={{ verticalAlign: 'middle' }}>
														<a onClick={() => removeRow('InstalledDate', 'Product', x.seq)} style={{ cursor: 'pointer' }}>
															<ion-icon name="remove-circle" style={{ fontSize: '25px', color: 'red', cursor: 'pointer', marginLeft: '3px' }} ></ion-icon>
														</a>
													</div>
												</div>
											)
										}) : <div style={{ marginTop: '10px', fontStyle:'italic' }}>등록된 기준값이 없습니다.</div>
									}
									</div>
									
								</div>
								<div style={{ display: 'inline-block', width: '49%', verticalAlign: 'top' }}>
									<div className='d-flex justify-content-between'>
										<label>설치일 기준 주요파트 보증기간</label>
										<button
											className='btn btn-primary mt-0'
											style={{ fontSize: '13px', height: '22px', padding: '0 5px', marginLeft: '10px' }}
											onClick={() => addRow('InstalledDate', "MainParts")}
										>+{t('add')}</button>
									</div>
									<div className='w-100'>
									{
										info.standards?.filter(x => x.baseDate === "InstalledDate" && x.type === "MainParts").length > 0 ? info.standards.filter(x => x.baseDate === "InstalledDate" && x.type === "MainParts").sort((a, b) => { return a.seq - b.seq }).map((x, idx) => {
											return (
												<div key={`info2_${idx}`} className='w-100 d-flex align-items-center'>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "from", onlyDate: true, val: x.from }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'MainParts', x.seq, e)} />
													</div>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "to", onlyDate: true, val: x.to }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'MainParts', x.seq, e)} />
													</div>
													<div style={{ width: '100px', flexGrow : 1 }}>
														<InputBasicNumber
															options={{ val: x.months || undefined, name: "months", placeholder: "Month(s)", positive: true }}
															editable={true}
															onchange={(e) => setStandards('InstalledDate', 'MainParts', x.seq, e)}
														/>
													</div>
													<div style={{ verticalAlign: 'middle' }}>
														<a onClick={() => removeRow('InstalledDate', 'MainParts', x.seq)} style={{ cursor: 'pointer' }}>
															<ion-icon name="remove-circle" style={{ fontSize: '25px', color: 'red', cursor: 'pointer', marginLeft: '3px' }} ></ion-icon>
														</a>
													</div>
												</div>
											)
										}) : <div style={{ marginTop: '10px', fontStyle:'italic' }}>등록된 기준값이 없습니다.</div>
									}
									</div>
								</div>
								<div style={{ display: 'inline-block', width: '49%', verticalAlign: 'top', marginRight:'14px', marginTop : 14 }}>
									<div className='d-flex justify-content-between'>
										<label>선적일 기준 제품 보증기간</label>
										<button
											className='btn btn-primary mt-0'
											style={{ fontSize: '13px',  height: '22px', padding: '0 5px', marginLeft: '10px' }}
											onClick={() => addRow('ShipmentDate', 'Product')}
										>+{t('add')}</button>
									</div>
									<div className='w-100'>
									{
										info.standards?.filter(x => x.baseDate === "ShipmentDate" && x.type === "Product").length > 0 ? info.standards.filter(x => x.baseDate === "ShipmentDate" && x.type === "Product").sort((a, b) => { return a.seq - b.seq }).map((x, idx) => {
											return (
												<div key={`info3_${idx}`} className='w-100 d-flex align-items-center'>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "from", onlyDate: true, val: x.from }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'Product', x.seq, e)} />
													</div>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "to", onlyDate: true, val: x.to }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'Product', x.seq, e)} />
													</div>
													<div style={{ width: '100px', flexGrow : 1 }}>
														<InputBasicNumber
															options={{ val: x.months || undefined, name: "months", placeholder: "Month(s)", positive: true }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'Product', x.seq, e)}
														/>
													</div>
													<div style={{ verticalAlign: 'middle' }}>
														<a onClick={() => removeRow('ShipmentDate', 'Product', x.seq)} style={{ cursor: 'pointer' }}>
															<ion-icon name="remove-circle" style={{ fontSize: '25px', color: 'red', cursor: 'pointer', marginLeft: '3px' }} ></ion-icon>
														</a>
													</div>
												</div>
											)
										}) : <div style={{ marginTop: '10px', fontStyle:'italic' }}>등록된 기준값이 없습니다.</div>
									}
									</div>
								</div>
								<div style={{ display: 'inline-block', width: '49%', verticalAlign: 'top', marginTop : 14 }}>
									<div className='d-flex justify-content-between'>
										<label>선적일 기준 주요파트 보증기간</label>
										<button
											className='btn btn-primary mt-0'
											style={{ fontSize: '13px', height: '22px', padding: '0 5px', marginLeft: '10px' }}
											onClick={() => addRow('ShipmentDate', "MainParts")}
										>+{t('add')}</button>
									</div>
									<div className='w-100'>
									{
										info.standards?.filter(x => x.baseDate === "ShipmentDate" && x.type === "MainParts").length > 0 ? info.standards.filter(x => x.baseDate === "ShipmentDate" && x.type === "MainParts").sort((a, b) => { return a.seq - b.seq }).map((x, idx) => {
											return (
												<div key={`info4_${idx}`} className='w-100 d-flex align-items-center'>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "from", onlyDate: true, val: x.from }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'MainParts', x.seq, e)} />
													</div>
													<div style={{ width: '30%', marginRight: '5px' }}>
														<InputBasicDateTime
															options={{ type: "datetime", name: "to", onlyDate: true, val: x.to }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'MainParts', x.seq, e)} />
													</div>
													<div style={{ width: '100px',flexGrow : 1 }}>
														<InputBasicNumber
															options={{ val: x.months || undefined, name: "months", placeholder: "Month(s)", positive: true }}
															editable={true}
															onchange={(e) => setStandards('ShipmentDate', 'MainParts', x.seq, e)}
														/>
													</div>
													<div style={{ verticalAlign: 'middle' }}>
														<a onClick={() => removeRow('ShipmentDate', 'MainParts', x.seq)} style={{ cursor: 'pointer' }}>
															<ion-icon name="remove-circle" style={{ fontSize: '25px', color: 'red', cursor: 'pointer', marginLeft: '3px' }} ></ion-icon>
														</a>
													</div>
												</div>
											)
										}) : <div style={{ marginTop: '10px', fontStyle:'italic' }}>등록된 기준값이 없습니다.</div>
									}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</Modal.Body>
			<Modal.Footer>
				{data._id && UserType(user?.companyId) === 'HQ' && <button className={"btn btn-block"} style={{ color : 'white', background : 'red'}} onClick={() => {
					del({});
				}}>{delOk ? '정말 삭제요?' : t('delete')}</button>}
				<button className={request ? "btn btn-block btn-primary disabled" : "btn btn-block btn-primary"} onClick={() => handleSubmit()}>
					{request && <Loader
						type='ThreeDots'
						color='#fff'
						width={30}
						height={30}
						visible={true}
						radius={10}
						style={{ display: 'inline-block', marginRight: '10px' }} />}

					{!request && (info._id ? t('update') : t('add'))}
				</button>
				<button className={request ? "btn btn-block btn-dark disabled" : "btn btn-block btn-dark"} onClick={() => {
					setInfo({});
					setDelOk(false); 
					callbackClose();
				}}>{t('close')}</button>
			</Modal.Footer>
		</Modal>
	);
}

const mapState = (state) => {
	var companyList = state.CompanyReducer.companies;
	var item = state.RayDataReducer.item;
	var clist = state.RayCodeReducer.items;
	return { item, companyList, clist };
};

const mapDispatch = (dispatch) => ({
	//GetCompany: () => dispatch(CompanyAction.GetCompany()),
	GetAll: (dataType, companyId) => dispatch(RayDataAction.GetAll(dataType, companyId)),
	Update: (type, _id, info) => dispatch(RayDataAction.Update(type, _id, info)),
	Create: (type, info) => dispatch(RayDataAction.Create(type, info)),
});

export default connect(mapState, mapDispatch)(RayDataAddUpdate);
