import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { AppConfig, JWT } from '../../config';
import { connect } from 'react-redux';
import { CompanyAction } from '../../actions';
import { CountryCode, CompanyTypes } from '../../config';
import { RayForm } from '../../components/common';
import AdminCompanyDelete from './AdminCompanyDelete';

const AdminCompanyEdit = ({ company, GetCompanyInfo, UpdateCompanyInfo }) => {

    const userInfo = JSON.parse(localStorage.getItem("user"));

    const { companyId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [view, setView] = useState("editcompany");
    const [editable, setEditable] = useState(company.companyId == "ADD" ? true : false);

    const [isnew, setIsNew] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [delCheckResult, setDelCheckResult] = useState({ userCount: 0, siteCount: 0, childCompanyCount: 0 });
    const [companyName, setCompanyName] = useState(company ? company.name : "");
    const [companies, setCompanies] = useState([]);
    const [userCombo, setUserCombo] = useState([]);

    useEffect(() => {
        GetCompanyInfo(companyId)
        getCompanyUsers(companyId);
    }, [])

    useEffect(() => {
        setIsLoading(false)
    }, [company])

    let form_controls = [
        {
            title: "Company Information", items: [
                { type: "input", name: "name", label: "Name", val: company.name, required: true, isEditable: editable },
                { type: "select", name: "country", label: "Country", val: company.country, list: CountryCode, required: true, isEditable: editable },
                {
                    type: "select",
                    name: "org",
                    label: "Organization",
                    val: company.org,
                    list: CompanyTypes.filter(x => userInfo?.isMegagen ? x.code != 'HQ' : x.code == x.code),
                    required: true,
                    isEditable: editable
                },
                { type: "select2", name: "operator", label: "Default Operator", val: company.operator || [], list: userCombo, required: true, isEditable: editable, isMulti: true },
                { type: "input", name: "state", label: "State", val: company.state, isEditable: editable },
                { type: "tel", name: "zip", label: "Zip", val: company.zip, isEditable: editable },
                { type: "input", name: "region", label: "Region", val: company.region, isEditable: editable },
                {
                    type: "combo",
                    name: "parentCompanyId",
                    label: "Parent Company",
                    val: company.parentCompanyId,
                    startval: "None",
                    getCombo: CompanyAction.GetCombo,
                    isEditable: editable
                },
                { type: "input", name: "address", label: "Address", val: company.address, size: 12, isEditable: editable },
                { type: "input", name: "addressExtra", label: "Address Extra.", val: company.addressExtra, size: 12, isEditable: editable },
                { type: "textarea", name: "note", label: "Note", val: company.note, size: 12, isEditable: editable },
            ]
        },
        {
            title: "Contact Information", items: [
                { type: "email", name: "contactEmail", label: "Email", val: company.contactEmail, required: true, isEditable: editable },
                { type: "tel", name: "contactTel", label: "Tel", val: company.contactTel, isEditable: editable },
                { type: "tel", name: "contactHp", label: "Mobile", val: company.contactHp, isEditable: editable },
                { type: "tel", name: "contactFax", label: "Fax", val: company.contactFax, isEditable: editable },
                { type: "input", name: "contactWebsite", label: "Website", val: company.contactWebsite, size: 12, isEditable: editable },
                { type: "input", name: "ownerName", label: "Owner Name", val: company.ownerName, isEditable: editable },
                { type: "tel", name: "ownerHp", label: "Owner Mobile", val: company.ownerHp, isEditable: editable },
                { type: "email", name: "ownerEmail", label: "Owner Email", val: company.ownerEmail, isEditable: editable },
            ]
        },
    ];

    form_controls = [{ title: form_controls[0].title, items: form_controls[0].items.filter(x => companyId == 'RAY02091' ? (x.name != 'org' && x.name != 'parentCompanyId') : x.name == x.name) }, form_controls[1]];


    const getCompanyUsers = async (companyId) => {
        var list = [];
        const ret = await axios.get(AppConfig.API_SERVER_PATH + '/user/getcomboByCompanyId/' + companyId, JWT());
        if (ret.data.status == 'success') {
            list = ret.data.data;
        }
        setUserCombo(list);
    }

    return (
        <>
            {isLoading && <div className="">Loading...</div>}
            {!isLoading &&
                <>
                    <div className="extraHeader">
                        <h2 className="mb-0">
                            {company.name}
                            {
                                view == "editcompany" &&
                                <a href onClick={() => setEditable(!editable)} className="btn btn-secondary btn-sm mt-0 ms-1">
                                    <ion-icon name={editable ? "lock-open-outline" : "lock-closed-outline"} />
                                    {editable ? "Unlocked" : "Locked"}
                                </a>}
                        </h2>
                        <div className="ray-right">
                            {company.companyId != "ADD" &&
                                <>
                                    {view != "editcompany" && <button className="btn btn-primary btn-sm mt-0 me-1" onClick={() => setView('editcompany')}>Company Info.</button>}
                                    {view != "delcompanyconfirm" && <button className="btn btn-danger btn-sm mt-0 me-1" onClick={() => setView('delcompanyconfirm')}>Delete Company</button>}
                                </>
                            }
                            <Link to="/Admin/AdminCompanyList" className="btn btn-default btn-sm mt-0 me-1" >List</Link>
                        </div>
                    </div>
                    <div id="appCapsule" className="extra-header-active full-height">
                        <div className="section mt-1 mb-1 ">
                        </div>
                        {view == "editcompany" &&
                            <RayForm controls={form_controls}
                                action={UpdateCompanyInfo}
                                actionkey={company.companyId}
                                errors={errors} />
                        }
                        {view == "delcompanyconfirm" &&
                            <div className="section ">
                                <AdminCompanyDelete company={company} />
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
};

const mapState = (state) => {
    const company = state.CompanyReducer.company;
    return { company };
}

const mapDispatch = dispatch => ({
    DeleteCompany: (companyid) => dispatch(CompanyAction.DeleteCompany(companyid)),
    UpdateCompanyInfo: (companyid, data) => dispatch(CompanyAction.UpdateCompanyInfo(companyid, data)),
    GetCompanyInfo: (companyId) => dispatch(CompanyAction.GetCompanyInfo(companyId)),
})

export default connect(mapState, mapDispatch)(AdminCompanyEdit);
