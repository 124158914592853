import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SiteSurveyAction, RayCodeAction } from '../../actions';
import { RayForm, RayFormValidation } from '../common';
import { Translate as t } from '../../config/Translate';

const SurveyStep1 = ({ Update, info, isSummary = false, gostep, clist }) => {
	const [errors, setErrors] = useState([]);
	const [isAddProcess, setIsAddProcess] = useState(false);
	const [siteSurvey, setSiteSurvey] = useState({ ...info, siteSurveyStep: 3 });
	const [category, setCategory] = useState("info");
	const [allModelOptionList, setAllModelOptionList] = useState([]);
	const [models, setModels] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (gostep) {
			if (gostep.curStep == 2) {
				var errValidate = [];
				var errValidate1 = RayFormValidation(form_controls1, siteSurvey);
				var errValidate2 = RayFormValidation(form_controls2, siteSurvey);
				var errValidate3 = RayFormValidation(form_controls3, siteSurvey);
				errValidate = errValidate.concat(errValidate1);
				errValidate = errValidate.concat(errValidate2);
				errValidate = errValidate.concat(errValidate3);
				if (errValidate.length > 0) {
					setErrors(errValidate);
				} else {
					setIsAddProcess(true);
					Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: gostep.goStepNo });
				}
			}
		}
	}, [gostep])

	useEffect(() => {
		var list = [];

		const isMg = JSON.parse(localStorage.getItem("user"))?.isMegagen;

		if (!isMg) {
			allModelOptionList.map(x => {
				if (list.filter(m => m.code == x.group).length == 0)
					list.push({ code: x.group, title: x.group });
			});
			setOptionList(siteSurvey.model || "RAYSCAN α+ (NEW)");

		} else {
			list.push({ code: 'R2 Studio Q', title: 'R2 Studio Q' });
			setOptionList('R2 Studio Q');
		}

		setModels(list)

	}, [allModelOptionList])

	const setOptionList = (model) => {
		var list = [];
		allModelOptionList.filter(x => x.group == model).map(x => {
			if (list.filter(m => m.code == x.code).length == 0)
				list.push({ code: x.code, title: x.name });
		});
		setOptions(list);
	}

	useEffect(() => {
		setOptionList(siteSurvey.model);
	}, [siteSurvey.model])

	useEffect(() => {
		console.log('SurveyStep1 ---> ', clist?.filter(x => x.type === 'PRODUCTOPTION'));
		setAllModelOptionList(clist?.filter(x => x.type === 'PRODUCTOPTION'));
		// RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }).then((e) => {
		// 	setAllModelOptionList(e);
		// });

		if (!siteSurvey.model) {
			setSiteSurvey({ ...siteSurvey, ["model"]: "RAYSCAN α+ (NEW)" });
		}
	}, [clist])

	const handleChangeData = (e) => {
		const { name, value } = e.target;
		console.log({ name, value });
		setSiteSurvey({ ...siteSurvey, [name]: value });
	};

	const Prev = (e) => {
		Update(siteSurvey._id, { ...siteSurvey, siteSurveyStep: 1 });
	};

	const form_controls1 = [
		{
			title: t('installation'), items: [
				{ type: "select", name: "model", label: t('model'), val: siteSurvey.model || "RAYSCAN α+ (NEW)", list: models, isEditable: !isSummary },
				{ type: "select2", name: "option", label: t('option'), val: siteSurvey.option, list: options, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "datetime", name: "scheduleDate", label: t('surveyschduled'), val: siteSurvey.scheduleDate, isEditable: !isSummary },
				{ type: "datetime", name: "trainingDate", label: t('surveytraininddate'), val: siteSurvey.trainingDate, isEditable: !isSummary }
			]
		},

		{
			title: t('additionalorders'), items: [
				{ type: "number", name: "additionalMainUnit", label: t('mainunit'), size: 3, val: siteSurvey.additionalMainUnit, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalPortableXray", label: t('portable'), size: 3, val: siteSurvey.additionalPortableXray, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalThreedPrinter", label: t('threedprinter'), size: 3, val: siteSurvey.additionalThreedPrinter, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalCurningUnit", label: t('curingunit'), size: 3, val: siteSurvey.additionalCurningUnit, isEditable: !isSummary, positive: true }
			]
		},
		{
			title: "", items: [
				{ type: "number", name: "additionalRioSensorSize1", label: t('riosensorsize1'), size: 3, val: siteSurvey.additionalRioSensorSize1, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalRioSensorSize2", label: t('riosensorsize2'), size: 3, val: siteSurvey.additionalRioSensorSize2, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalRioScanStandard", label: t('rioscanstandard'), size: 3, val: siteSurvey.additionalRioScanStandard, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalRioScanOcctusal", label: t('rioscanocclusal'), size: 3, val: siteSurvey.additionalRioScanOcctusal, isEditable: !isSummary, positive: true }
			]
		},
		{
			title: "", items: [
				{
					type: "select", name: "additionalObjectScankit", label: t('objectscankit'), val: siteSurvey.additionalObjectScankit || t('none'),
					list: [
						{ code: "None", title: t('none') },
						{ code: "Charged", title: t('charged') },
						{ code: "Complimentary", title: t('complimentary') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "additionalRayCeph", label: "RayCeph", val: siteSurvey.additionalRayCeph || t('none'),
					list: [
						{ code: "None", title: t('none') },
						{ code: "Charged", title: t('charged') },
						{ code: "Complimentary", title: t('complimentary') },
					], isEditable: !isSummary
				}
			]
		},
		{
			title: "", items: [
				{
					type: "select", name: "additionalRayScanweb", label: "RAYSCAN Web", val: siteSurvey.additionalRayScanweb || t('none'),
					list: [
						{ code: "None", title: t('none') },
						{ code: "Charged", title: t('charged') },
						{ code: "Complimentary", title: t('complimentary') },
					], isEditable: !isSummary
				},
				{ type: "input", name: "additionalRemark", label: t('remarks'), val: siteSurvey.additionalRemark, isEditable: !isSummary }
			]
		},

		{
			title: t('resin'), items: [
				{ type: "number", name: "additionalCb500", label: "C&B / 500ml", size: 3, val: siteSurvey.additionalCb500, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalCb1l", label: "C&B / 1L", size: 3, val: siteSurvey.additionalCb1l, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalSg500", label: "SG / 500ml", size: 3, val: siteSurvey.additionalSg500, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalSg1l", label: "SG / 1L", size: 3, val: siteSurvey.additionalSg1l, isEditable: !isSummary, positive: true }
			]
		},
		{
			title: "", items: [
				{ type: "number", name: "additionalDb500", label: "DM / 500ml", size: 3, val: siteSurvey.additionalDb500, isEditable: !isSummary, positive: true },
				{ type: "number", name: "additionalDb1l", label: "DM / 1L", size: 3, val: siteSurvey.additionalDb1l, isEditable: !isSummary, positive: true }
			]
		},

		{
			title: t('environment'), items: [
				{ type: "number", name: "environmentUnitChairs", label: t('unitchairs'), size: 3, val: siteSurvey.environmentUnitChairs, isEditable: !isSummary, positive: true },
				{ type: "number", name: "environmentClientPc", label: t('clientpc'), size: 3, val: siteSurvey.environmentClientPc, isEditable: !isSummary, positive: true },
			]
		},
		{
			title: "", items: [
				{ type: "number", name: "environmentWalmountXray", label: t('wallmount'), size: 3, val: siteSurvey.environmentWalmountXray, isEditable: !isSummary, positive: true },
				{ type: "input", name: "environmentWalmountXrayManufacture", label: t('wallmount') + ' ' + t('manufacturer'), val: siteSurvey.environmentWalmountXrayManufacture, isEditable: !isSummary },
				{ type: "number", name: "environmentPotableXray", label: t('portable'), size: 3, val: siteSurvey.environmentPotableXray, isEditable: !isSummary, positive: true },
				{ type: "input", name: "environmentPotableXrayManufacture", label: t('portable') + ' ' + t('manufacturer'), val: siteSurvey.environmentPotableXrayManufacture, isEditable: !isSummary }
			]
		},
	]
	const form_controls2 = [
		{
			title: t('shieldroom'), items: [
				{
					type: "select", name: "shieldroomConstruction", label: t('construction'), val: siteSurvey.shieldroomConstruction,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Required", title: t('required') },
						{ code: "Not required", title: t('notrequired') },
					], isEditable: !isSummary
				},
				{ type: "input", name: "shieldroomOutputVtg", label: t('outputvoltage') + "(V)", val: siteSurvey.shieldroomOutputVtg, isEditable: !isSummary },
				{
					type: "select", name: "shieldroomOutputHz", label: t('outputvoltage') + "(Hz)", val: siteSurvey.shieldroomOutputHz || "50Hz",
					list: [
						{ code: "50Hz", title: "50Hz" },
						{ code: "60Hz", title: "60Hz" },
					], isEditable: !isSummary
				}
			]
		},
		{
			title: "", items: [
				{
					type: "select", name: "shieldroomGrounded", label: t('grounded'), size: 3, val: siteSurvey.shieldroomGrounded,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					], isEditable: !isSummary
				},
				{ type: "input", name: "shieldroomlGroundVoltage1", label: t('l1ground'), size: 3, val: siteSurvey.shieldroomlGroundVoltage1, isEditable: !isSummary },
				{ type: "input", name: "shieldroomlGroundVoltage2", label: t('l2ground'), size: 3, val: siteSurvey.shieldroomlGroundVoltage2, isEditable: !isSummary },
				{
					type: "select", name: "shieldroomAvrCharged", label: t('avr'), size: 3, val: siteSurvey.shieldroomAvrCharged,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Required", title: t('required') },
						{ code: "Not required", title: t('notrequired') },
					], isEditable: !isSummary
				},
			]
		},
	]
	const form_controls3 = [
		{
			title: t('workstation'), items: [
				{
					type: "select", name: "workstationMonitor", label: t('monitor'), size: 4, val: siteSurvey.workstationMonitor,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Inside room", title: t('insideroom') },
						{ code: "Outside room", title: t('outsideroom') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "workstationLocation", label: t('location'), size: 4, val: siteSurvey.workstationLocation,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Inside room", title: t('insideroom') },
						{ code: "Outside room", title: t('outsideroom') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "workstationShelf", label: t('shelf'), size: 4, val: siteSurvey.workstationShelf,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					], isEditable: !isSummary
				}
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "workstationRemark", label: t('remarks'), val: siteSurvey.workstationRemark, isEditable: !isSummary }
			]
		},

		{
			title: t('exposureswitch'), items: [
				{
					type: "select", name: "exposureswitchLocation", label: t('location'), size: 4, val: siteSurvey.exposureswitchLocation,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Inside room", title: t('insideroom') },
						{ code: "Outside room", title: t('outsideroom') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "exposureswitchInterlock", label: t('interlock'), size: 4, val: siteSurvey.exposureswitchInterlock,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Required", title: t('required') },
						{ code: "Not required", title: t('notrequired') },
					], isEditable: !isSummary
				},
				{ type: "input", name: "exposureswitchInterlockModel", label: t('interlock') + ' ' + t('model'), size: 4, val: siteSurvey.exposureswitchInterlockModel, isEditable: !isSummary }
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "exposureswitchRemark", label: t('remarks'), val: siteSurvey.exposureswitchRemark, isEditable: !isSummary }
			]
		},

		{
			title: t('software'), items: [
				{ type: "input", name: "softwareMainProduct", label: t('mainviewerproduct'), size: 3, val: siteSurvey.softwareMainProduct, isEditable: !isSummary },
				{ type: "input", name: "softwareMainModel", label: t('mainviewermodel'), size: 3, val: siteSurvey.softwareMainModel, isEditable: !isSummary },
				{ type: "input", name: "softwareThreedProduct", label: t('threedviewerproduct'), size: 3, val: siteSurvey.softwareThreedProduct, isEditable: !isSummary },
				{ type: "input", name: "softwareThreedModel", label: t('threedviewermodel'), size: 3, val: siteSurvey.softwareThreedModel, isEditable: !isSummary },
				{ type: "input", name: "softwareCephProduct", label: t('cephtracingproduct'), size: 3, val: siteSurvey.softwareCephProduct, isEditable: !isSummary },
				{ type: "input", name: "softwareCephModel", label: t('cephtracingmodel'), size: 3, val: siteSurvey.softwareCephModel, isEditable: !isSummary },
				{ type: "input", name: "softwareOtherProduct", label: t('otherproduct'), size: 3, val: siteSurvey.softwareOtherProduct, isEditable: !isSummary },
				{ type: "input", name: "softwareOtherModel", label: t('othermodel'), size: 3, val: siteSurvey.softwareOtherModel, isEditable: !isSummary },
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "softwareRemark", label: t('remarks'), val: siteSurvey.softwareRemark, isEditable: !isSummary }
			]
		},

		{
			title: t('network'), items: [
				{
					type: "select", name: "networkConstruction", label: t('construction'), size: 4, val: siteSurvey.networkConstruction,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Required", title: t('required') },
						{ code: "Not required", title: t('notrequired') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "networkRouter", label: t('router'), size: 4, val: siteSurvey.networkRouter,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "networkRouterType", label: t('toutertype'), size: 4, val: siteSurvey.networkRouterType,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Wired", title: t('wired') },
						{ code: "Wired + Wireless", title: t('wiredwireless') },
					], isEditable: !isSummary
				},
				{
					type: "select", name: "networkInternet", label: t('internet'), size: 4, val: siteSurvey.networkInternet,
					list: [
						{ code: "None", title: t('none') },
						{ code: "Yes", title: t('yes') },
						{ code: "No", title: t('no') },
					], isEditable: !isSummary
				}
			]
		},
		{
			title: "", items: [
				{ type: "input", name: "networkRemark", label: t('remarks'), val: siteSurvey.networkRemark, isEditable: !isSummary }
			]
		},
	];

	const validNext = () => {
		var errValidate = [];
		var errValidate1 = RayFormValidation(form_controls1, siteSurvey);
		var errValidate2 = RayFormValidation(form_controls2, siteSurvey);
		var errValidate3 = RayFormValidation(form_controls3, siteSurvey);
		errValidate = errValidate.concat(errValidate1);
		errValidate = errValidate.concat(errValidate2);
		errValidate = errValidate.concat(errValidate3);
		if (errValidate.length > 0) {
			setErrors(errValidate);
		} else {
			setIsAddProcess(true);
			Update(siteSurvey._id, siteSurvey);
		}
	};

	return (
		<>
			<div className={isSummary ? "" : "section mt-2"}>
				<ul className={isSummary ? "nav nav-tabs style3 mb-1" : "nav nav-tabs style2"}>
					<li className="nav-item">
						<a href
							className={category == "info" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("info")}>{t('productinfo')}</a>
					</li>
					<li className="nav-item">
						<a href
							className={category == "room" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("room")}>{t('shieldroom')}</a>
					</li>
					<li className="nav-item">
						<a href
							className={category == "detail" ? "nav-link active" : "nav-link"}
							onClick={() => setCategory("detail")}>{t('installdetails')}</a>
					</li>
				</ul>
			</div>
			{category == "info" &&
				<RayForm controls={form_controls1} showAllFields={true} isSummary={isSummary} isForm={!isSummary} handleChangeData={handleChangeData} errors={errors} />
			}
			{category == "room" &&
				<RayForm controls={form_controls2} showAllFields={true} isSummary={isSummary} isForm={!isSummary} handleChangeData={handleChangeData} errors={errors} />
			}
			{category == "detail" &&
				<RayForm controls={form_controls3} showAllFields={true} isSummary={isSummary} isForm={!isSummary} handleChangeData={handleChangeData} errors={errors} />
			}

			{!isSummary &&
				<div className="section mt-1 mb-2">
					<button className="btn btn-primary btn-block btn-lg" onClick={() => validNext()}>{t('next')}</button>
					<button className="btn btn-dark btn-block btn-lg mt-1" onClick={() => Prev()}>{t('back')}</button>
				</div>
			}
		</>
	);
};

const mapState = (state) => {
	const clist = state.RayCodeReducer.items;
	return { clist };
};

const mapDispatch = (dispatch) => ({
	Update: (_id, data) => dispatch(SiteSurveyAction.UpdateSiteSurvey(_id, data)),
});

export default connect(mapState, mapDispatch)(SurveyStep1);