
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ProductExtendedWarrantyAdd from './ProductExtendedWarrantyAdd';
import { RayTable } from '../common';
import { ProductAction, RayCodeAction } from '../../actions';
import moment from 'moment';
import { AdminUserAction } from '../../actions';
import { Translate as t } from '../../config/Translate';
import { UserType } from '../../config';
import { RayDataAction } from '../../actions/RayDataAction';
import Loader from 'react-loader-spinner';
import { sDeviceModels } from '../../config/RayCodes';

const ProductInfoWarranty = ({ product, users, user, codeList, GetUserAll, GetCodeListAll }) => {

    const [showModal, setShowModal] = useState(false);
    const [mfWarranty, setMfWarranty] = useState([]);
    const [brWarranty, setBrWarranty] = useState([]);
    const [exWarranty, setExWarranty] = useState([]);
    const [warrantyHistory, setWarrantyHistory] = useState([]);
    const [userType, setUserType] = useState('DEALER');
    const [request, setRequest] = useState(false);
    const [smallDevice, setSmallDevice] = useState(false);
 
    const getName = (val) => {
        var n = codeList.filter(x => x.type == "WARRANTY" && x.code == val);
        return n.length > 0 ? n[0].name : val;
    }

    const getUserName = (adminId) => {
        let result = users.find(item => item.adminid == adminId);

        if (result?.name)
            return result?.name || '';
        else
            return result?.firstName || '' + result?.lastName || ''
    }

    const applyExWarranty = (category, dest, period) => {
        let applied = period;
        const type = category === "wartype_ms" ? "HQ" : "BRANCH";
        product.exWarranty?.forEach(x => {
            if (x.type === type && x.dest.includes(dest))
                applied = moment(applied).add(x.amount, x.amountType);
        });

        return applied;
    }

    useEffect(() => {
        console.log("product info : =====", product);
        if(sDeviceModels.find(f => f.code === product?.model)) {
            setSmallDevice(true);
        }
        GetCodeListAll();
        GetUserAll();
    }, []);

    useEffect(() => {
        setMfWarranty(product.waranties.filter(x => x.category == 'wartype_ms'));
        setBrWarranty(product.waranties.filter(x => x.category == 'wartype_bs'));

        const warrantyLog = product.warantylog ? product.warantylog.map(x => {
            const attachment = x.hasOwnProperty("attachment") && x.attachment;
            return {
                date: moment(x.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                type: getName(x.category),
                product: x.dest.includes('warproduct_product') ? 'O' : '',
                cbctDetector: x.dest.includes('warproduct_cbct') ? 'O' : '',
                panoDetector: x.dest.includes('warproduct_pano') ? 'O' : '',
                cephDetector: x.dest.includes('warproduct_ceph') ? 'O' : '',
                tubeTank: x.dest.includes('warproduct_tubetank') ? 'O' : '',
                inverterboard: x.dest.includes('warproduct_inverterboard') ? 'O' : '',
                duration: x.amount + " " + (x.amounttype == "d" && "Day(s)" || x.amounttype == "M" && "Month(s)" || x.amounttype == "y" && "Year(s)"),
                reason: x.why,                
                applied: users.find(item => item.adminid === x.who)?.name || '',
                attachmentPath: attachment.length > 0 ? attachment[0].path : "",
                attachmentOrigin: attachment.length > 0 ? attachment[0].origin : ""
            }
        }) : [];

        setWarrantyHistory(warrantyLog.sort((a, b) => {
            const date_1 = new Date(a.date);
            const date_2 = new Date(b.date);

            if (date_1 < date_2) return 1;
            if (date_1 > date_2) return -1;

            return 0;
        }));

        const temp = [];

        product.exWarranty?.filter(x => x.type === (userType === "HQ" ? "HQ" : "BRANCH")).forEach(x => {
            x.dest.forEach(y => {
                const prev = temp.find(z => z.type === x.type && z.dest === y);
                if (!prev) {
                    if (x.amountType === "y") temp.push({ type: x.type, dest: y, year: x.amount });
                    if (x.amountType === "M") temp.push({ type: x.type, dest: y, month: x.amount });
                    if (x.amountType === "d") temp.push({ type: x.type, dest: y, day: x.amount });
                } else{
                    if (x.amountType === "y") prev["year"] = parseInt(prev["year"] || 0) + x.amount;
                    if (x.amountType === "M") prev["month"] = parseInt(prev["month"] || 0) + x.amount;
                    if (x.amountType === "d") prev["day"] = parseInt(prev["day"] || 0) + x.amount;
                }
            });
        });

        setExWarranty(temp);
    }, [product, userType])

    useEffect(() => {
        setUserType(UserType(user.companyId));        
    }, [user]);

    useEffect(() => { 
        console.log("mfWarranty : ", mfWarranty);
    }, [mfWarranty]); 

    useEffect(() => {
        console.log('exWarranty============================================');
        console.log(exWarranty);
    }, [exWarranty]);

    const setWarranty = async () => {
        setRequest(true);

        const response = await RayDataAction.SetWarranty(product.sn, "HQ", "CT");

        if (response.status === "success") {
            const product = response.data;

            if (response.data?.waranties)
                setMfWarranty(product.waranties.filter(x => x.category == 'wartype_ms'));
        }

        setRequest(false);
    }

    const warranty_history_header = [
        { name: 'date', label: t('date') },
        { name: 'type', label: t('type') },
        { name: 'product', label: t('product'), type: 'TF', align: 'text-center' },
        { name: 'cbctDetector', label: 'CBCT Detector', type: 'TF', align: 'text-center' },
        { name: 'panoDetector', label: 'Pano Detector', type: 'TF', align: 'text-center' },
        { name: 'cephDetector', label: 'Ceph Detector', type: 'TF', align: 'text-center' },
        { name: 'tubeTank', label: 'Tube Tank', type: 'TF', align: 'text-center' },
        { name: 'inverterboard', label: 'Inverter Board', type: 'TF', align: 'text-center' },
        { name: 'applied', label: 'Applied' },
        { name: 'duration', label: t('duration') },
        { name: 'reason', label: t('reason') },
        { name: 'attachment', label: t('attachments'), type: 'DOWNLOADBUTTON' },
    ];

    const warranty_history_header_smallDevice = [
        { name: 'date', label: t('date') },
        { name: 'type', label: t('type') },
        { name: 'product', label: t('product'), type: 'TF', align: 'text-center' },
        { name: 'applied', label: 'Applied' },
        { name: 'duration', label: t('duration') },
        { name: 'reason', label: t('reason') },
        { name: 'attachment', label: t('attachments'), type: 'DOWNLOADBUTTON' },
    ]
 
    return (
        <div className="row mb-1 p-2">
            {
                user.adminid === "raycpt" &&
                <div style={{ textAlign: 'right' }}>
                    <button className='btn btn-primary btn-sm mt-0' type="button" onClick={() => setWarranty()} disabled={request}>
                    <Loader
                                type='TailSpin'
                                color='#fff'
                                width={15}
                                height={15}
                                visible={request}
                                radius={2}
                                style={{ display: 'inline-block', marginRight: '10px' }} />
                        Set Manufacturer Warranty
                        </button>
                </div>
            }
            
            {
                userType !== 'DEALER' &&
                <div className="col m-0 p-1">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title m-0">{t('manufacturerwarranty')}</div>
                        </div>

                        <ul className="listview simple-listview">
                            {
                                mfWarranty.length > 0 && mfWarranty.filter(f => smallDevice ? f?.dest != "warproduct_xray" : true ).map((x, idx) => {
                                    return <li key={`mf_${idx}`} className="border-bottom">
                                        <div>{getName(x.dest)}</div>
                                        <div>
                                            <span>                                                
                                                {moment(applyExWarranty(x.category, x.dest, x.period)).format("YYYY-MM-DD")}
                                            </span>
                                        </div>
                                    </li>
                                })
                            }
                            {
                                mfWarranty.length == 0 && <div className="card-body">
                                    <h5>{t('norecords')}</h5>
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            }

            {
                <div className="col m-0 p-1">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title m-0">{t(userType !== 'DEALER' ? 'branchwarranty' : 'manufacturerwarranty')}</div>
                        </div>

                        <ul className="listview simple-listview">
                            {
                                brWarranty.length > 0 && brWarranty.map((x, idx) => {
                                    return <li key={`br_${idx}`} className="border-bottom">
                                        <div>{getName(x.dest)}</div>
                                        <div>
                                            <span>                                                
                                                {moment(applyExWarranty(x.category, x.dest, x.period)).format("YYYY-MM-DD")}
                                            </span>
                                        </div>
                                    </li>
                                })
                            }
                            {
                                brWarranty.length == 0 && <div className="card-body">
                                    <h5>{t('norecords')}</h5>
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            }

            <div className="col m-0 p-1">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title m-0 float-start">{t('extendedwarranty')}</div>
                        {
                            (product.waranties.filter(x => x.category == 'wartype_ms').length > 0 && user.adminType == 'S') &&
                            <div className='float-end'><a className='btn btn-sm mt-0 right' onClick={() => setShowModal(true)}><ion-icon name="add-circle-outline" ></ion-icon> {t('add')}</a></div>
                        }
                    </div>

                    <ul className="listview simple-listview">
                        {
                            exWarranty.length > 0 && exWarranty.map((x, idx) => {
                                return <li key={`exWar_${idx}`} className="border-bottom">
                                    <div>{getName(x.dest)}</div>
                                    <div>
                                        {/* <span className={x.period > new Date().getTime() ? "badge badge-primary me-1" : "badge badge-danger me-1"}>
                                            {moment(x.period).fromNow()}
                                        </span>
                                        <span>
                                            {moment(x.period).format("YYYY-MM-DD")}
                                        </span> */}
                                        <span>
                                            {x.year && x.year + ' Year(s) '}
                                            {x.month && x.month + ' Month(s) '}
                                            {x.day && x.day + ' Day(s)'}
                                        </span>
                                    </div>
                                </li>
                            })
                        }

                        {
                            exWarranty.length == 0 && <div className="card-body">
                                <h5>{t('norecords')}</h5>
                            </div>
                        }

                    </ul>
                </div>
                {/* {(product.waranties.filter(x => x.category == 'wartype_ms').length > 0 && user.adminType == 'S') && <button className="btn btn-primary btn-block btn-sm" onClick={() => setShowModal(true)}><ion-icon name="add-outline"></ion-icon>{t('add')}</button>} */}
            </div>

            <div className="col m-0 p-1">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title m-0">{t('upgradekitwarranty')}</div>
                    </div>

                    <ul className="listview simple-listview">
                        <li>
                            <h5>{t('norecords')}</h5>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="card">
                <div className="card-header">
                    <div className="card-title m-0">{t('warrantyhistory')}</div>
                </div>
                <div className="card-body">
                    <RayTable
                        data={warrantyHistory}
                        onlytable={true}
                        columns={smallDevice ? warranty_history_header_smallDevice : warranty_history_header}

                        options={{
                            keyCol: "_id",
                            filterColumns: [],
                        }} />
                </div>
            </div>

            <ProductExtendedWarrantyAdd showModal={showModal} codeList={codeList} callbackClose={() => setShowModal(false)} />
        </div>
    )
};


const mapState = (state) => {
    const product = state.ProductReducer.product;
    const users = state.AdminUserReducer.users;
    const user = state.AuthReducer.user;
    const codeList = state.RayCodeReducer.items;
    return { product, users, user, codeList };
};

const mapDispatch = (dispatch) => ({
    GetUserAll: () => dispatch(AdminUserAction.GetUserAll()),
    GetCodeListAll: () => dispatch(RayCodeAction.GetAll()),
    SetProductWarranty: (sn, data) => dispatch(ProductAction.SetProductWarranty(sn, data))
});

export default connect(mapState, mapDispatch)(ProductInfoWarranty);