import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ServiceAction, JiraAction, ProductAction } from '../../actions';
import { history } from '../../history';
import ServiceInfoView from '../../components/service/ServiceInfoView';
import ServiceInfoStatus from '../../components/service/ServiceInfoStatus';
import ServiceComments from '../../components/service/ServiceInfoComments';
import ServiceInfoLogs from '../../components/service/ServiceInfoLogs';
import ServiceModalJiraCreate from '../../components/service/ServiceModalJiraCreate';
import ServiceInfoAttachments from '../../components/service/ServiceInfoAttachments';
import ServiceClaimCategory from '../../components/service/ServiceClaimCategory';
import SiteInfo from '../../components/site/SiteInfo';
import ProductErrorInfo from '../../components/product/ProductErrorInfo';
import ProductInfo from '../../components/product/ProductInfo';
import ServiceReplacement from '../../components/service/ServiceReplacement';
import { ServiceStatusIcon } from '../../components/service/ServiceCard';
import { ccrGeneratePDF } from '../../components/common/ExportPdfReport';
import { ServiceJiraInfo } from '../../components/service/ServiceJiraInfo';
import { ServiceJiraComments } from '../../components/service/ServiceJiraComments';
import CustomFieldValues from '../../components/customfield/CustomFieldValues';
import { NoRecord } from '../HomePage';
import { Translate as t } from '../../config/Translate';
import { Loading } from '../../components/common/Loading';

const AdminServiceInfo = ({ GetServiceByTicketKey, service, GetProduct, GetJira, product, user, issueInfo, UpdateService }) => {

	//const [t, i18n] = useTranslation();
	const { ticketKey, view } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [tab, setTab] = useState(view || "comment");
	const [editable, setEditable] = useState(false);
	const [delAlert, setDelAlert] = useState(false);
	const [actioninfo, setActionInfo] = useState({});
	const [nodata, setNoData] = useState(false);

	useEffect(() => {
		setIsLoading(false)
		if (service.sn) {
			GetProduct(service.sn);
		}
		if (service.jirakey) {
			GetJira(service.jirakey, service._id);
		}
		setNoData(ticketKey != service.ticketKey);
	}, [service])

	useEffect(() => {
		GetServiceByTicketKey(ticketKey);
	}, [ticketKey])

	const getLengthStr = (objArr) => {
		if (objArr) {
			return objArr.length > 0 ? "(" + objArr.length + ")" : "";
		}
		return "";
	}

	const getJiraComments = () => {
		if (issueInfo.fields) {
			if (issueInfo.fields.comment) {
				return issueInfo.fields.comment.comments.length > 0 ? "(" + issueInfo.fields.comment.comments.length + ")" : "";
			}
		}
	}

	const tabList = [
		{ code: "comment", title: t('comments') + getLengthStr(service.comments) },
		{ code: "Jira", title: "Jira Comments" + getJiraComments() },
		{ code: "Replacements", title: t('replacements') + getLengthStr(service.replacements) },
		{ code: "logs", title: t('logs') + getLengthStr(service.histories) }
	]

	const DelService = async () => {
		const result = await ServiceAction.DeleteSimple(service._id);
		if (result) {
			history.push("/Admin/AdminServiceList");
		}
	}

	const ExportCCR = async () => {
		const result = await ServiceAction.GetForExport(service.ticketKey);
		if (result.data.status == "success") {
			ccrGeneratePDF(result.data.data, t);
		} else {
			//ERROR!
		}
	}

	const CreateJiraDone = (data) => {
		console.log(data);
	}

	const CreateJira = () => {
		setActionInfo({
			...actioninfo,
			title: "Create Jira Issue", showModal: true,
			actions: [
				{ title: "Create Issue", callback: CreateJiraDone }
			]
		})
	}

	const showJiraCreate = () => {
		console.log(service.jirakey + " - " + issueInfo.key)
		if (user.companyInfo.org != "HQ")
			return false;
		if (service.jirakey != undefined && service.jirakey == issueInfo.key)
			return false;

		return true
	}

	const UpdateExtra = (code, value) => {
		UpdateService(service._id, {
			customfieldvals: { ...service.customfieldvals, [code]: value }
		});
	}

	const ServiceReport = ()=>{
		history.push({
			pathname: '/Admin/AdminServiceExportCSR',
			state: {
				item: service,
			},
		});
	}

	return (
		<>
			{!isLoading &&
				<>
					{(!nodata && service._id) && <>
						<div className="extraHeader">
							<h2 className="mb-0">
								<div className="float-start">
									<ServiceStatusIcon item={service} css="item" />
								</div>
								<div className="float-start mt-05 ms-1">
									{service.summary}&nbsp;
									<a href onClick={() => setEditable(!editable)} className="btn btn-secondary btn-sm mt-0">
										<ion-icon name={editable ? "lock-open-outline" : "lock-closed-outline"} />
										{editable ? t('unlocked') : t('locked')}
									</a>
								</div>
							</h2>
							<div className="ray-right">
								{/* {showJiraCreate() && user.adminType == 'S' && <button className="btn btn-info btn-sm mt-0 me-1" onClick={() => CreateJira()}>Create JIRA</button>} */}
								{(user.adminType == 'S' && !user?.isMegagen) && <button className="btn btn-success btn-sm mt-0 me-1" onClick={() => ServiceReport()}>Service Report</button>}
								<button className="btn btn-danger btn-sm mt-0 me-1" onClick={() => setDelAlert(true)}>Delete Ticket</button>
								<Link to="/Admin/AdminServiceList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
							</div>
						</div>
						<div id="appCapsule" className="extra-header-active full-height">
							<div className="section mt-1 mb-1">
								{!delAlert &&
									<>
										<div className="row">
											<div className="col pe-1">
												<div className="card mb-1">
													<h3 className="card-header">{t('information')}</h3>
													<ServiceInfoView service={service} editable={editable} />
												</div>
												<div className="card mb-1">
													<div className="card m-1">
														<ul className="nav nav-tabs lined">
															{tabList.map(x => {
																if (!(issueInfo.key && user.companyInfo.org == "HQ" && service.jirakey == issueInfo.key) && x.code == "Jira") {
																	return;
																}
																return (
																	<li className="nav-item" key={x.code}>
																		<a className={tab == x.code ? "nav-link active" : "nav-link"}
																			onClick={() => setTab(x.code)}>{x.title}</a>
																	</li>
																)
															})}
														</ul>
														<div className="pt-0">
															{(issueInfo.key && user.companyInfo.org == "HQ" && service.jirakey == issueInfo.key && tab == "Jira") && <ServiceJiraComments issueInfo={issueInfo} />}
															{/* tab == "Attachments" && <ServiceInfoAttachments service={service} />*/}
															{tab == "Replacements" && <ServiceReplacement service={service} onlyContents={true} />}
															{tab == "comment" && <ServiceComments service={service} />}
															{tab == "logs" && <ServiceInfoLogs service={service} />}
														</div>
													</div>
												</div>
											</div>
											<div className="col ps-0">
												<div className="row">
													<div className="col">
														<div className="card mb-1">
															<ServiceClaimCategory editable={editable} key={service} />
														</div>
														{service.attachments &&
															<div className="card mb-1">
																<h3 className="card-header">{t('attachments')}</h3>
																<div className='card'>
																	<ServiceInfoAttachments service={service} />
																</div>
															</div>}
														<div className="card mb-1">
															<h3 className="card-header">User/Date</h3>
															<ServiceInfoStatus service={service} editable={editable} nocard={true} />
														</div>
													</div>
													<div className="col ps-0">
														<CustomFieldValues model={"SERVICE"} update={UpdateExtra} data={service.customfieldvals || {}} />
														{service.productErrorCodeId &&
															<div className="card mb-1">
																<h3 className="card-header">Product Error Information</h3>
																<ProductErrorInfo productErrorCodeId={service.productErrorCodeId} sn={service.sn} nocard={true} />
															</div>}
														{product &&
															<div className="card mb-1">
																<h3 className="card-header">{t('productinfo')}</h3>
																<ProductInfo productInfo={product} showsn={true} nocard={true} />
															</div>}
														{service.siteId &&
															<div className="card mb-1">
																<h3 className="card-header">{t('siteinfo')}</h3>
																<SiteInfo siteId={service.siteId} showTitle={true} nocard={true} />
															</div>}
													</div>
												</div>

												{(issueInfo.key && user.companyInfo.org == "HQ" && service.jirakey == issueInfo.key) &&
													<ServiceJiraInfo issueInfo={issueInfo} nocard={true} />
												}
											</div>
										</div>
									</>
								}
								{delAlert &&
									<>
										<div className="card bg-danger text-white">
											<div className="card-header">
												<div className="card-title">경고!</div>
											</div>
											<div className="card-body">
												<div className="card-title">즉시 반영됩니다!!!</div>
												<p>이 작업은 되돌릴 수 없는 작업입니다. 정말 삭제하시겠습니까?</p>
											</div>
										</div>
										<button className="btn btn-danger" onClick={() => DelService()}>Delete Service</button>
										<button className="btn btn-default ml-2" onClick={() => setDelAlert(false)}>Cancel</button>
									</>
								}
							</div>
						</div>
						<ServiceModalJiraCreate
							info={actioninfo}
							service={service}
							callbackClose={() => setActionInfo({ ...actioninfo, showModal: false })} />
					</>}
					{(nodata && service._id) && <><div className="extraHeader">
						<h2 className="mb-0">
							<div className="float-start">Service Information</div>
							<div className="float-start mt-05 ms-1">
							</div>
						</h2>
						<div className="ray-right">
							<Link to="/Admin/AdminServiceList" className="btn btn-outline-default btn-sm mt-0 me-1">List</Link>
						</div>
					</div>
						<div id="appCapsule" className="extra-header-active full-height">
							<div className="pt-1">
								<NoRecord />
							</div>
						</div>
					</>}
				</>
			}
			{isLoading && <div className="" style={{ marginTop : 120 }}>
				<Loading></Loading>
			</div>}
		</>
	);
};

const mapState = (state) => {
	const service = state.ServiceReducer.service;
	const product = state.ProductReducer.product;
	const user = state.AuthReducer.user;
	const issueInfo = state.JiraReducer.issueInfo;

	return { service, product, user, issueInfo };
};

const mapDispatch = (dispatch) => ({
	GetServiceByTicketKey: (ticketKey) => dispatch(ServiceAction.GetServiceByTicketKey(ticketKey)),
	GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
	UpdateService: (_id, data) => dispatch(ServiceAction.UpdateService(_id, data)),
	GetJira: (jirakey, _id) => dispatch(JiraAction.Get(jirakey, _id)),
});

export default connect(mapState, mapDispatch)(AdminServiceInfo);