import React, { useEffect, useState } from 'react';
import { Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from './history';
import { AuthRoute, AdminAuthRoute } from './AuthRoute';
import LoggedBottomMenu, { LoggedFooter } from './components/common/CommonViews';
import { LoginPage, ForgotPassword, ResetNewPassword, ForgotPasswordSent, IubendaLogin } from './pages/auth';
import { UserAccount, UserSettings, PasswordConfirm, UpdatePassword } from './pages/user';
import { MyTask } from './pages/mytask';
import ServiceDetail from './pages/service/ServiceDetail';
import ErrorCodeLookup from './pages/errorcode/ErrorCodeLookup';
import {
	AdminLogin, AdminCalendar, AdminUserAdd, AdminUserEdit, AdminUserList, AdminCompanyList, AdminCompanyEdit, AdminCompanyAdd, AdminProductAdd,
	AdminErrorCodeList, AdminErrorCodeAdd, AdminErrorCodeInfo, AdminProductDashboard, AdminServiceDashboard, AdminWarrantyDashboard,
	AdminServiceList, AdminServiceAdd, AdminServiceInfo, AdminServiceExportCSR, AdminRayCode, AdminRayCodeInfo, AdminProductPart, AdminCustomFieldList,
	AdminProductStockList, AdminProductImport, AdminSiteDashboard, AdminRayDataList, AdminSetting, AdminProductImportSmall,
	AdminInstallationList, AdminInstallationInfo, AdminErrorList, AdminErrorInfo, AdminErrorDashboard, AdminProductPartInfo,
	AdminProductList, AdminProductInfo, AdminSiteList, AdminSiteEdit, AdminSiteAdd, AdminSiteSurveyList, AdminSiteSurveyInfo, AdminDashboard, AdminStatistics,
	AdminTranslate, AdminSpareParts, AdminSparePartsDetail, AdminSparePartsOrder, AdminSparePartsOrderFree, AdminSparePartsInRegist, AdminSparePartsStock, AdminSparePartsCompany, AdminSparePartsCompanyDetail,
	AdminSparePartsOrderList, AdminSparePartsOrderListManager, AdminSparePartsOrderDetail, AdminSparePartsOutRegist, AdminSparePartsDealerOutRegist, AdminSparePartsOutList, AdminSparePartsOutDetail,
} from './pages/admin';

import { SiteListView } from './pages/site';
import { AlertAction, AuthAction, CompanyAction, CustomFieldAction, RayCodeAction, TranslateAction } from './actions';
import { AppLoader, AppHeader, TopMenu, SideMenu } from './components/common';
import { useTranslation } from 'react-i18next';
import HomePage from './pages/HomePage';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './asset/css/common.css';
import "react-datepicker/dist/react-datepicker.css";
import AdminLeftMenu from './components/common/AdminLeftMenu';
import AdminTopMenu from './components/common/AdminTopMenu';
import { LoggedAdminFooter, Toast } from './components/common/CommonViews';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyFavorite from './pages/mytask/MyFavorite';
import MySearch from './pages/mytask/MySearch';
import ProductRegistByUser from './pages/product/ProductRegistByUser';
import MyInfo from './pages/user/MyInfo';
import SiteView from './pages/site/SiteView';
import ServiceAddNew from './pages/service/ServiceAddNew';
import InstallationGuide from './pages/guides/InstallationGuide';
import SettingWizardGuide from './pages/guides/SettingWizardGuide';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ProductView from './pages/product/ProductView';
import QRScan from './pages/mytask/QRScan';
import SiteSurvey from './pages/mytask/SiteSurvey';
import Installation from './pages/mytask/Installation';
import MyReportManager from './pages/user/MyReportManager';
import MyGuide from './pages/mytask/MyGuide';
import { UserInfo } from './pages/user/UserInfo';
import ProductErrorEdit from './components/product/ProductErrorEdit';
import MyNotification from './pages/mytask/MyNotification';
import MyCalendar from './pages/mytask/MyCalendar';

import moment from 'moment';
import localeEN from 'moment/locale/en-gb';
import localeZHCN from 'moment/locale/zh-cn';
import localeZHTW from 'moment/locale/zh-tw';
import localeSP from 'moment/locale/es-us';
import localeDE from 'moment/locale/de';
import localeKO from 'moment/locale/ko';
import localeJA from 'moment/locale/ja';
import localeFR from 'moment/locale/fr';
import AdminSparePartsCIPublish from './pages/admin/AdminSparePartsCIPublish';
import AdminTenYears from './pages/admin/AdminTenYears';

const EmtryCom = () => {
	return <></>
}
const App = ({ alert, user, companies, userToken, isLogin, translate, AlertClose, GetAllCompany, GetAllCustomField }) => {
	const [isAdmin, setIsAdmin] = useState(location.pathname.toLowerCase().startsWith('/admin/'));
	const [alertToast, setAlertToast] = useState({ type: "success", show: false, message: "" });
	const [isDashboard, setIsDashboard] = useState(location.pathname.toLowerCase().includes('/admin/dashboard'));
	const [standAlone, setStandAlone] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const setLocale = () => {
		const user = localStorage.getItem('user');
		if (user) {
			const language = JSON.parse(user).rguardSettingLanguage;
			if (language) {
				switch (language) {
					case "deu": moment.updateLocale("de", localeDE); break;
					case "eng": moment.updateLocale("en-gb", localeEN); break;
					case "spa": moment.updateLocale("es-us", localeSP); break;
					case "cmn": moment.updateLocale("zh-cn", localeZHCN); break;
					case "zhh": moment.updateLocale("zh-tw", localeZHTW); break;
					case "jpn": moment.updateLocale("ja", localeJA); break;
					case "kor": moment.updateLocale("ko", localeKO); break;
					case "fre": moment.updateLocale("fr", localeFR); break;
				}
			}

			TranslateAction.SearchByLanguageDirect(language).then(translate => {
				localStorage.setItem('translate', JSON.stringify(translate));
			});
		}
	}

	useEffect(() => {
		if (isLogin) {
			dispatch(CompanyAction.GetCompanyByToken(userToken));
		}
	}, [isLogin]);

	useEffect(() => {
		setLoading(true);
		setLocale();
		setStandAlone(location.pathname.toLowerCase().startsWith('/register'))
		const user = localStorage.getItem('user');
		const clist = localStorage.getItem('clist');
		if (user && clist) {
			if (!JSON.parse(user).permAdminAccess && location.pathname.toLowerCase().startsWith('/admin/')) {
				window.location = "/Home";
			}
			if (location.pathname.toLowerCase().startsWith('/admin/')) {
				//GetAllCompany();
				GetAllCustomField();
			}
			init();
		}else{
			setLoading(false);
			dispatch(AuthAction.Logout());
			history.push("/Login");
		}
	}, []);

	const init = async () => {
		dispatch(RayCodeAction.GetAll());
		setLoading(false);
	}

	useEffect(() => {
		if (alert.message != "" && !alert.isClose) {
			if (alert.type == "alert-success") {
				setAlertToast({ type: "success", show: true, message: alert.message });
			} else {
				setAlertToast({ type: "error", show: true, message: alert.message });
			}
		}
		const timer = setTimeout(() => setAlertToast({ show: false }), 3 * 1000);
		return () => clearTimeout(timer);
	}, [alert]);

	return (
		<div id="App">
			<Router history={history}>
				{standAlone && <>
					<Switch>
						<Route exact path="/register" component={ProductRegistByUser} />
					</Switch>
				</>}
				{!loading && !standAlone && !isAdmin && (		//Tech 페이지
					<>
						{isLogin && <SideMenu />}
						<Switch>
							<AuthRoute path="/Home" component={HomePage} />
							<AuthRoute path="/MyInfo/:view?" component={MyInfo} />
							<AuthRoute path="/UserAccount" component={UserAccount} />
							<AuthRoute path="/MyFavorite/:view?" component={MyFavorite} />
							<AuthRoute path="/MySearch/:view?" component={MySearch} />
							<AuthRoute path="/UserSettings" component={UserSettings} />
							<AuthRoute path="/PasswordConfirm" component={PasswordConfirm} />
							<AuthRoute path="/UpdatePassword" component={UpdatePassword} />
							<AuthRoute path="/Sites" component={SiteListView} />
							<AuthRoute path="/MyTask/:view?" component={MyTask} />
							<AuthRoute path="/ReportManager/:view?" component={MyReportManager} />
							<AuthRoute path="/Site/:siteId/:view?" component={SiteView} />
							<AuthRoute path="/Service/:ticketKey?" component={ServiceDetail} />
							<AuthRoute path="/Product/:sn/:view?" component={ProductView} />
							<AuthRoute path="/ServiceAddNew" component={ServiceAddNew} />
							<AuthRoute path="/Calendar" component={MyCalendar} />
							<AuthRoute path="/Guides/:view?" component={MyGuide} />
							<AuthRoute path="/InstallationGuide" component={InstallationGuide} />
							<AuthRoute path="/SettingWizardGuide" component={SettingWizardGuide} />
							<AuthRoute path="/QRScan" component={QRScan} />
							<AuthRoute path="/Notification" component={MyNotification} />
							<AuthRoute path="/UserInfo/:adminid" component={UserInfo} />
							<AuthRoute path="/SiteSurvey/:_id?/:view?" component={SiteSurvey} />
							<AuthRoute path="/Installation/:_id?/:view?" component={Installation} />
							<Route path="/ForgotPassword" component={ForgotPassword} />
							<Route path="/ResetNewPassword" component={ResetNewPassword} />
							<Route path="/ForgotPasswordSent" component={ForgotPasswordSent} />
							<Route path="/IubendaLogin" component={IubendaLogin} />
							<Route exact path="/Login" component={LoginPage} />
							<AuthRoute exact path="/" component={HomePage} />
							<Route path="/Terms">
								<Redirect push to={"terms.html"} />
								<Redirect push to={"terms_kr.html"} />
							</Route>
						</Switch>
						{isLogin && <ErrorCodeLookup />}
						{isLogin && <LoggedBottomMenu />}
						{isLogin && <ProductErrorEdit />}
					</>
				)}
				{!loading && !standAlone && isAdmin && (
					<div className="rayadmin">
						{isLogin && <AdminTopMenu isDashboard={isDashboard} />}
						{isLogin && <AdminLeftMenu />}
						<Switch>
							<AuthRoute path="/Admin/Dashboard" component={AdminDashboard} />
							<AuthRoute path="/Admin/AdminCalendar" component={AdminCalendar} />
							<AuthRoute path="/Admin/AdminUserList" component={AdminUserList} />
							<AuthRoute path="/Admin/AdminUserAdd" component={AdminUserAdd} />
							<AuthRoute path="/Admin/AdminUserInfo/:adminid/:view?" component={AdminUserEdit} />
							<AuthRoute path="/Admin/AdminCompanyList" component={AdminCompanyList} />
							<AuthRoute path="/Admin/AdminCompanyInfo/:companyId/:view?" component={AdminCompanyEdit} />
							<AuthRoute path="/Admin/AdminCompanyAdd" component={AdminCompanyAdd} />
							<AuthRoute path="/Admin/AdminCompanyInfo/:companyId" component={AdminCompanyEdit} />

							<AuthRoute path="/Admin/AdminErrorDashboard" component={AdminErrorDashboard} />
							<AuthRoute path="/Admin/AdminWarrantyDashboard" component={AdminWarrantyDashboard} />
							<AuthRoute path="/Admin/AdminTenYears" component={AdminTenYears} />

							<AuthRoute path="/Admin/AdminProductImport" component={AdminProductImport} />
							<AuthRoute path="/Admin/AdminProductImportSmall" component={AdminProductImportSmall} />
							<AuthRoute path="/Admin/AdminProductDashboard" component={AdminProductDashboard} />
							<AuthRoute path="/Admin/AdminProductList" component={AdminProductList} />
							<AuthRoute path="/Admin/AdminProductStockList" component={AdminProductStockList} />
							<AuthRoute path="/Admin/AdminProductAdd" component={AdminProductAdd} />
							<AuthRoute path="/Admin/AdminProductInfo/:sn/:view?" component={AdminProductInfo} />

							<AuthRoute path="/Admin/AdminErrorInfo/:eCode/:sn/:errId/:view?" component={AdminErrorInfo} />
							<AuthRoute path="/Admin/AdminErrorList" component={AdminErrorList} />

							<AuthRoute path="/Admin/AdminSiteList" component={AdminSiteList} />
							<AuthRoute path="/Admin/AdminSiteDashboard" component={AdminSiteDashboard} />
							<AuthRoute path="/Admin/AdminErrorCodeList" component={AdminErrorCodeList} />
							<AuthRoute path="/Admin/AdminErrorCodeAdd" component={AdminErrorCodeAdd} />
							<AuthRoute path="/Admin/AdminErrorCodeInfo/:errorCode/:lang?/:view?" component={AdminErrorCodeInfo} />
							<AuthRoute path="/Admin/AdminErrorCodeInfoById/:_id" component={AdminErrorCodeInfo} />
							<AuthRoute path="/Admin/AdminSiteInfo/:id/:view?" component={AdminSiteEdit} />
							<AuthRoute path="/Admin/AdminSiteAdd" component={AdminSiteAdd} />

							<AuthRoute path="/Admin/AdminSiteSurveyList" component={AdminSiteSurveyList} />
							<AuthRoute path="/Admin/AdminSiteSurveyInfo/:id" component={AdminSiteSurveyInfo} />

							<AuthRoute path="/Admin/AdminInstallationList" component={AdminInstallationList} />
							<AuthRoute path="/Admin/AdminInstallationInfo/:id" component={AdminInstallationInfo} />

							<AuthRoute path="/Admin/AdminRayDataList" component={AdminRayDataList} />
							<AuthRoute path="/Admin/AdminNotification" component={MyNotification} />

							<AuthRoute path="/Admin/AdminCustomFieldList" component={AdminCustomFieldList} />

							<AuthRoute path="/Admin/AdminServiceDashboard" component={AdminServiceDashboard} />
							<AuthRoute path="/Admin/AdminServiceList" component={AdminServiceList} />
							<AuthRoute path="/Admin/AdminServiceAdd" component={AdminServiceAdd} />
							<AuthRoute path="/Admin/AdminService/:ticketKey" component={AdminServiceInfo} />
							<AuthRoute path="/Admin/AdminServiceExportCSR" component={AdminServiceExportCSR} />

							<AuthRoute path="/Admin/AdminRayCode" component={AdminRayCode} />
							<AuthRoute path="/Admin/AdminRayCodeInfo" component={AdminRayCodeInfo} />

							<AuthRoute path="/Admin/AdminProductPart" component={AdminProductPart} />
							<AuthRoute path="/Admin/AdminProductPartInfo" component={AdminProductPartInfo} />

							<AuthRoute path="/Admin/AdminConditionSearch" component={AdminStatistics} />
							<AuthRoute path="/Admin/AdminSetting" component={AdminSetting} />

							<AuthRoute path="/Admin/AdminTranslate" component={AdminTranslate} />

							<AuthRoute path="/Admin/AdminSparePartsStock" component={AdminSparePartsStock} />

							<AuthRoute path="/Admin/AdminSpareParts" component={AdminSpareParts} />
							<AuthRoute path="/Admin/AdminSparePartsDetail/:_id" component={AdminSparePartsDetail} />
							<AuthRoute path="/Admin/AdminSparePartsOrder" component={AdminSparePartsOrder} />
							<AuthRoute path="/Admin/AdminSparePartsOrderFree" component={AdminSparePartsOrderFree} />
							
							<AuthRoute path="/Admin/AdminSparePartsOrderList/" component={AdminSparePartsOrderList} />							
							
							<AuthRoute path="/Admin/AdminSparePartsOrderListManager" component={AdminSparePartsOrderListManager} />
							<AuthRoute path="/Admin/AdminSparePartsOrder/:_id" component={AdminSparePartsOrderDetail} />
							<AuthRoute path="/Admin/AdminSparePartsInRegist/:_id" component={AdminSparePartsInRegist} />
							<AuthRoute path="/Admin/AdminSparePartsOutRegist/:_id" component={AdminSparePartsOutRegist} />
							<AuthRoute path="/Admin/AdminSparePartsDealerOutRegist" component={AdminSparePartsDealerOutRegist} />
							
							<AuthRoute path="/Admin/AdminSparePartsOutList" component={AdminSparePartsOutList} />
							<AuthRoute path="/Admin/AdminSparePartsOutDetail/:_id" component={AdminSparePartsOutDetail} />

							<AuthRoute path="/Admin/AdminSparePartsCompany" component={AdminSparePartsCompany} />
							<AuthRoute path="/Admin/AdminSparePartsCompanyDetail/:_id" component={AdminSparePartsCompanyDetail} />

							<AuthRoute path="/Admin/AdminSparePartsCIPublish" component={AdminSparePartsCIPublish} />

							<AuthRoute path="/Admin/AdminEmpty" component={EmtryCom} />

							<Route exact Path="/Admin/Login" component={AdminLogin} />
							<Route path="/Terms">
								<Redirect push to={"terms.html"} />
								<Redirect push to={"terms_kr.html"} />
							</Route>
						</Switch>
						{isLogin && <LoggedAdminFooter />}
						<ToastContainer />
					</div>
				)}
				<Toast option={alertToast} />
			</Router>
		</div>
	);
};

const mapState = (state) => {
	return {
		alert: state.AlertReducer,
		isLogin: state.AuthReducer.loggingIn,
		userToken: state.AuthReducer.userToken,
		user: state.AuthReducer.user,
		companies: state.CompanyReducer.companies
	};
};

const mapDispatch = (dispatch) => ({
	AlertClose: () => dispatch(AlertAction.Closed()),
	//GetAllCompany: () => dispatch(CompanyAction.GetCompany()),
	GetAllCustomField: () => dispatch(CustomFieldAction.GetAll()),
});

export default connect(mapState, mapDispatch)(App);
