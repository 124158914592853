import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { RayTable, RayTreeview, AdminProductListHeader } from '../../components/common';
import { InputSelect2 } from '../../components/common/Inputs';
import DeepSearch from '../../components/common/DeepSearch';
import CustomHeader from '../../components/common/CustomHeader';
import { ProductAction } from '../../actions/ProductAction';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import { ProductConstant } from '../../actions/constants/ProductConstant';
import { CustomFieldAction } from '../../actions';
import { Translate as t } from '../../config/Translate';
import axios from 'axios';
import { AppConfig, JWT } from '../../config';
import { utils, writeFile } from 'xlsx';
import Loader from 'react-loader-spinner';

const ModalDIMDOAClear = ({ showModal, type, callback, callbackClose }) => {
	const [comment, setComment] = useState("");

	return (
		<Modal show={showModal} onHide={() => callbackClose(false)} className="action-sheet">
			<Modal.Header>
				<h2 className="modal-title">{type == "CLEARDIM" ? "Clear DIM" : "Clear DOA"}</h2>
			</Modal.Header>
			<Modal.Body className="p-0">
				<ul className="listview simple-listview no-space border-0 m-2">
					<div className="form-group basic">
						<div className="input-wrapper">
							<label className="label">Description(Clear Reason)</label>
							<textarea className="form-control"
								rows={5}
								onChange={(e) => setComment(e.target.value)}
								defaultValue={comment}></textarea>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-block btn-primary mb-0"
					onClick={() => { callback(comment); callbackClose(false); }}>{type == "CLEARDIM" ? "Clear DIM" : "Clear DOA"}</button>
				<button className="btn btn-block btn-dark" onClick={() => callbackClose(false)}>Cancel</button>
			</Modal.Footer>
		</Modal>
	);
}

const ModalTransfer = ({ showModal, callback, callbackClose, tData, companies }) => {
	const [selval, setSelVal] = useState("");

	return (
		<Modal show={showModal} size="lg" onHide={() => callbackClose(false)} className="action-sheet" centered>
			<Modal.Header>
				<h2 className='modal-title'>Operator Transfer</h2>
			</Modal.Header>
			<Modal.Body className='p-0'>
				<ul className='listview simple-listview no-space border-0 m-2'>
					<div className='from-group basic'>
						<div className='input-wrapper'>
							<InputSelect2
								options={{
									name: "companyId",
									label: "Transfer Operator",
									val: selval,
									list: companies
								}}
								editable={true}
								onchange={(e) => setSelVal(e.target.value)}
							/>
						</div>
					</div>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<button className='btn btn-block btn-success mb-0' onClick={() => { callback(selval); callbackClose(false); }}>Transfer</button>
				<button className='btn btn-block btn-dark' onClick={() => callbackClose(false)}>Cancel</button>
			</Modal.Footer>
		</Modal>
	);
}

const AdminProductList = ({ customs, su, user, GetProductAll, BulkUpdateProductInfo, products, totalproductcount,
	filterDateOptions, filterOptions, sort, dsfilters, page, companies, viewTree, BulkUpdateTransferInfo }) => {

	const [header, setHeader] = useState([]);
	const [exheader, setExHeader] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showDSModal, setShowDSModal] = useState(false);
	const [showTModal, setShowTModal] = useState(false);
	const [bulkList, setBulkList] = useState([]);
	const [bulkType, setBulkType] = useState("");
	const [nowuserfilter, setnowuserfilter] = useState({});

	const [filters, setFilters] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [showCHModal, setShowCHModal] = useState(false);

	const [wHg, setwHg] = useState(300);
	const [wAll, setwAll] = useState(window.innerWidth);
	const [ready, setReady] = useState(false);
	const [match, setMatch] = useState({});
	const [filterCompanyId, setFilterCompanyId] = useState("");
	const [filterCompany, setFilterCompany] = useState({ name: "ALL" });
	const [dfilters, setdFilters] = useState(false);
	const dispatch = useDispatch();

	const [companyList, setCompanies] = useState([]);

	const [payloads, setPayloads] = useState({});
	const [downloading, setDownloading] = useState(false);

	const setCompanySelect = () => {
		const cList = [];
		companies.map((c) => cList.push({ code: c.companyId, title: "[" + c.country + "] " + c.name }));
		setCompanies(cList);
	};

	useEffect(() => {
		user.adminType != "U" && updateViewTree(true);
	}, []);

	const [tData, setTData] = useState({
		newsn: "",
		nowsn: "",
		companyId: user.companyId,
		reason: "",
	});

	var filterpage = {
		filters: {},
		filterOptions: ['model'],
		searchCols: ['model', 'option', 'sn', 'site.sitename'],
		sort: sort || { updateDate: -1 },
		searchKey: "",
		match: match,
		page: page || 1,
		itemperpage: 30
	};

	var options = {
		isShowCheckBox: true,
		search: true,
		keyCol: 'sn',
		defaultSort: filterpage.sort && Object.keys(filterpage.sort)[0],
		defaultSortType: filterpage.sort && Object.values(filterpage.sort)[0],
		linkto: '/Admin/AdminProductInfo',
		getList: GetProductAll,
		linkkey: 'sn',
		filterpageoption: filterpage,
		filters: filterpage.filters,
		itemperpage: filterpage.itemperpage,
		useapi: true,
		totalitemcount: totalproductcount,
		filterOptions: filterOptions
	};

	useEffect(() => {
		setFilters(user.filters || []);
	}, [user.filters]);

	useEffect(() => {
		setReady(header.length > 0);
	}, [header]);

	useEffect(() => {
		setExHeader(user.adminheaders?.products ? user.adminheaders?.products : [])
	}, [user.adminheaders?.products, dfilters]);

	useEffect(() => {
		setHeader(exheader.length > 0 ? AdminProductListHeader.concat(exheader) : AdminProductListHeader);
	}, [exheader]);

	useEffect(() => {
		if (viewTree) {
			setwHg(300);
		} else {
			setwHg(0);
		}
	}, [viewTree]);

	useEffect(() => {
		callbackDs(dsfilters);
	}, [dsfilters]);

	const getWidth = () => setwAll(window.innerWidth);

	useEffect(() => {
		dispatch({ type: ProgramConstant.SET_PRODUCTLISTISSTOCK, isstock: false })
	}, []);

	useEffect(() => {
		if (companies.length > 0)
			setCompanySelect();
	}, [companies]);

	const onChangeTreeviewItem = (item) => {
		setFilterCompanyId({ companyId: item.id });
		setFilterCompany(item);
	}

	const checkedList = (list) => {
		setBulkList(list);
	}

	const doBulkCallback = (comment) => {
		var data = bulkType == "CLEARDIM" ?
			{
				dim: false,
				"$push": {
					dimlogs: {
						comment: comment,
						user: user.adminid,
						dim: false,
						timestamp: new Date().getTime()
					}
				}
			} : {
				doa: false,
				"$push": {
					doalogs: {
						comment: comment,
						user: user.adminid,
						doa: false,
						timestamp: new Date().getTime()
					}
				}
			}
		setShowModal(false);
		BulkUpdateProductInfo(bulkList, data);
	}

	const doTransferBulkCallback = (selval) => {
		setShowTModal(false);
		BulkUpdateTransferInfo(bulkList, selval);
	}

	const bulkActionDo = (action) => {
		setBulkType(action);
		action == "OPTRANSFER" ? setShowTModal(true) : setShowModal(true);
	}

	const bulkActions = [
		{ title: "Clear DIM", action: "CLEARDIM" },
		{ title: "Clear DOA", action: "CLEARDOA" },
		{ title: "Transfer", action: "OPTRANSFER" }
	]

	const conSearch = () => {
		setShowDSModal(true);
	}

	const callbackchangeuserfilter = (data) => {
		setnowuserfilter(data);
		dispatch({ type: ProductConstant.UPDATE_DSFILTER, dsfilters: data.filter ? [...data.filter] : [] });
	}

	const callbackDs = (filter) => {
		var mt = {}
		filter.map(x => {
			if (x.type == "STRING" || x.type == "FIXLIST" || x.type == "NUMBER")
				mt[x.key] = x.val;
			if (x.type == "LIKESTRING")
				mt[x.key] = { $regex: x.val, $options: 'si' };
			if (x.type == "DATETIME") {
				mt[x.key] = {};
				if (x.val.opt == "last") {
					mt[x.key]['$gte'] = moment().add(-1 * x.val.lastVal, x.val.lastValOpt).valueOf();
				} else {
					mt[x.key]['$gte'] = x.val.s;
					mt[x.key]['$lte'] = x.val.e;
				}
			}
			if (x.type == "BOOLEAN")
				mt[x.key] = x.val;
		})
		setMatch(mt);
	}

	const defaultFilter = [
		//STRING, DATETIME, BOOLEAN
		{ code: "company.country", name: "Country", type: "COUNTRY" },
		{ code: "companyId", name: "Operator", type: "OPERATOR" },
		{ code: "errs.code", name: "Error Code", type: "ERRORCODES" },
		{ code: "codes", name: "Codes", type: "STRING" },
		{ code: "option", name: "Option", type: "OPTION" },
		{ code: "lastConnectedDate", name: "Last Connected Date", type: "DATETIME" },
		{ code: "lastDisconnectedDate", name: "Last Disconnected Date", type: "DATETIME" },
		{ code: "installedDate", name: "Install Date", type: "DATETIME" },
		{ code: "productTWId", name: "Thingworx ID", type: "STRING" },
		//{ code : "sitename", name : "Site Name(LIKE)", type : "LIKESTRING" }, // sites lookup 은 성은 이슈로 제외 20211229
		{ code: "isConnected", name: "Is Connected", type: "BOOLEAN" },
		{ code: "comments.comment", name: "Comment(LIKE)", type: "LIKESTRING" },
		{ code: "dim", name: "DIM", type: "BOOLEAN" }, //STRING, DATETIME, BOOLEAN
		{ code: "doa", name: "DOA", type: "BOOLEAN" },
		{ code: "registerd", name: "Registered", type: "DATETIME" },
		{ code: "manufactured", name: "Manufactured Date", type: "DATETIME" },
		{ code: "waranties.category", name: "Warranty Category", type: "WARRANTYTYPE" },
		{ code: "waranties.dest", name: "Warranty Product", type: "WARRANTYPRODUCT" },
		{ code: "waranties.period", name: "Warranty Period", type: "DATETIME" }
	]

	useEffect(() => {
		const newfilters = [];
		customs.filter(m => m.group.includes("PRODUCT")).map(x => {
			if (x.valuetype == "FIXLIST") {
				var mList = [];
				x.listvals.map(l => mList.push({ code: l, title: l }));
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title, fixlist: mList });
			} else {
				newfilters.push({ code: "customfieldvals." + x.code, type: x.valuetype, name: x.title });
			}
		});
		defaultFilter.map(x => newfilters.push(x));
		setdFilters(newfilters);
	}, [customs]);

	const updateViewTree = (bl) => {
		dispatch({ type: ProgramConstant.VIEWTREE, val: bl });
	}

	const exportAllDevices = async () => {
		setDownloading(true);

		let current_page = 1;
		let total_page = 0;
		const count_per_page = 20000;

		const result = [];

		while (true) {
			//const ret = await axios.post(AppConfig.API_SERVER_PATH + '/product', data, JWT());
			//const response = await axios.post('https://api.raymedical.com/api/product',
			const response = await axios.post(AppConfig.API_SERVER_PATH + '/product',
				{
					type: "get",
					data: {
						...payloads,
						page: current_page,
						itemperpage: count_per_page
					}
				}, JWT());

			if (response.data.status === 'success') {
				if (total_page === 0) {
					const total_count = response.data.data.totalcount;
					total_page = Math.ceil(total_count / count_per_page);
				}

				let newCols = [];
				let nowCols = JSON.parse(JSON.stringify(header));

				const data = response.data.data.list;

				nowCols.map(x => {
					if (x.type != 'BUTTON') {
						if (x.name.indexOf(".") > 0) {
							const newKey = x.name.replace(".", "");
							const keyarr = x.name.split(".");
							data?.map((y) => {
								try {
									y[newKey] = y[keyarr[0]][keyarr[1]];
								} catch { }
								return y
							})
							newCols.push({ ...x, name: newKey })
						}
						else {
							newCols.push(x)
						}
					}
				});

				newCols.map(x => {
					if (x.type == "DT" || x.type == "DATETIME" || x.type == "DTONLY") {
						data?.map(y => {
							y[x.name] = moment(y[x.name]).local().format('YYYY-MM-DD HH:mm:ss');
							return y
						})
					}
					if (x.type == "COMPANY" && companies.length > 0) {
						data?.map(y => {
							var cid = companies.filter(n => n.companyId == y[x.name]);
							if (cid.length > 0) {
								y[x.name] = cid[0].name
							}
							return y;
						})
					}
					if (x.type == 'ARRAYLIST') {
						data?.map(y => {
							y[x.name] = y[x.name].toString();
							return y;
						})
					}
				});

				result.push(...response.data.data.list.map(x => {
					let filtered = {};
					newCols.forEach(y => filtered[y.label] = x[y.name]);
					return filtered;
				}));
			}

			if (current_page === total_page)
				break;

			current_page++;
		}

		const ws = utils.json_to_sheet(result);
		const wb = utils.book_new();

		utils.book_append_sheet(wb, ws, "Sheet1");

		setDownloading(false);

		writeFile(wb, `Product-List-${moment().format("YYYYMMDDHHmmSS")}.xlsx`);
	}

	return (
		<>
			<div className="extraHeader">
				<h2 className="mb-0">{t('product')}
					{companies.length > 1 && <button className="btn btn-secondary btn-sm mt-0 ms-1" onClick={() => updateViewTree(!viewTree)}>
						{filterCompany.name && <>
							{filterCompany.country && "[" + filterCompany.country + "] "}{filterCompany.name}
						</>}
					</button>}
				</h2>
				<div className="ray-right">
					<button onClick={() => exportAllDevices()} className="btn btn-sm mt-0">
						{
							downloading &&
							<Loader
								type='TailSpin'
								color='#000'
								width={15}
								height={15}
								visible={downloading}
								radius={2}
								style={{ display: 'inline-block', marginRight: '10px' }} />
						}

						{
							!downloading &&
							<ion-icon name="cloud-download-outline"></ion-icon>
						}
						Export Data
					</button>

					<button onClick={() => setShowCHModal(true)} className="btn btn-sm mt-0">
						<ion-icon name="options-outline"></ion-icon> {t('customheaders')}
					</button>
					{
						(user.adminType == 'S' && user.companyId != 'RAY02091') && <Link to="/Admin/AdminProductImport" className="btn btn-sm mt-0">
							<ion-icon name="share-outline"></ion-icon> {t('importdevices')}
						</Link>
					}
					<Link to="/Admin/AdminProductDashboard" className="btn btn-sm mt-0">
						<ion-icon name="desktop-outline"></ion-icon> {t('dashboard')}
					</Link>
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				{(viewTree && user.adminType != "U") &&
					<div className={viewTree ? "section fl p-0" : "section p-0 fl"} style={{ width: wHg }}>
						<div className='p-1'>
							<RayTreeview onClickItem={onChangeTreeviewItem} />
						</div>
					</div>}
				<div className="section m-1 p-0">
					<div className="card p-2">
						<div className={user.adminType != "U" ? "fl" : ""} >
							{showDSModal && <div className="mb-1">
								<DeepSearch
									extratrakey={"PRODUCTEXTRAKEY"}
									extratrakeyname={"extradata"}
									filterData={filterData}
									dsfilters={dsfilters || []}
									nowuserfilter={nowuserfilter}
									callbackDs={callbackDs}
									callbackchangeuserfilter={callbackchangeuserfilter}
									callbackDSClose={() => setShowDSModal(false)}
									defaultFilter={su ? dfilters : dfilters.filter(x => x.code != "companyId")} />
							</div>}
							{ready && <RayTable
								data={products}
								listtype={"product"}
								ufilters={filters.length > 0 ? filters.filter(x => x.type == "product") : []}
								match={match}
								dsfilterCount={dsfilters.length}
								conSearch={conSearch}
								addFilter={filterCompanyId}
								exportlocalaction={ProductAction.GetProductAllLocal}
								bulkActions={bulkActions}
								bulkActionDo={bulkActionDo}
								callbackchangeuserfilter={callbackchangeuserfilter}
								checkBoxCallback={(list) => checkedList(list)}
								columns={header}
								options={options}
								payloadCallback={(pl) => setPayloads(pl)}
							/>}
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			</div>
			<CustomHeader
				extratrakey={"PRODUCTEXTRAKEY"}
				title={t('productlistheader')}
				extratrakeyname={"extradata"}
				exHeader={exheader}
				adminheaderprefix={"products"}
				showModal={showCHModal}
				callbackClose={() => setShowCHModal(false)}
				defaultFilter={dfilters || []} />
			<ModalDIMDOAClear showModal={showModal}
				callbackClose={() => setShowModal(false)}
				type={bulkType}
				callback={(comment) => doBulkCallback(comment)} />
			<ModalTransfer showModal={showTModal}
				callbackClose={() => setShowTModal(false)}
				type={bulkType}
				callback={(selval) => doTransferBulkCallback(selval)}
				tData={tData}
				companies={companyList}
			/>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var su = state.AuthReducer.su;
	var viewTree = state.ProgramReducer.viewtree;
	var companies = state.CompanyReducer.companies;
	var products = state.ProductReducer.products;
	var dsfilters = state.ProductReducer.dsfilters;
	var totalproductcount = state.ProductReducer.totalproductcount;
	var filterOptions = state.ProductReducer.filterOptions;
	var filterDateOptions = state.ProductReducer.filterDateOptions;
	var filters = state.ProductReducer.filters;
	var page = state.ProductReducer.page;
	var sort = state.ProductReducer.sort;
	const customs = state.CustomFieldReducer.items;
	return { customs, user, su, products, totalproductcount, filterOptions, filters, sort, filterDateOptions, dsfilters, page, viewTree, companies };
};

const mapDispatch = (dispatch) => ({
	//GetCustomAll: () => dispatch(CustomFieldAction.GetAll()),
	GetProductAll: (filter) => dispatch(ProductAction.GetProductAll(filter)),
	BulkUpdateProductInfo: (sns, data) => dispatch(ProductAction.BulkUpdateProductInfo(sns, data)),
	BulkUpdateTransferInfo: (sns, data) => dispatch(ProductAction.BulkUpdateTransferInfo(sns, data)),
});

export default connect(mapState, mapDispatch)(AdminProductList);
