import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AggrWarrantyDashBoard } from '../../config/Aggregates';
import WidgetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';
import { Translate as t } from '../../config/Translate';
import { Modal } from 'react-bootstrap';
import { InputBasicNumber } from '../../components/common/Inputs';
import { AuthAction, CommonAction } from '../../actions';
import { RayTable } from '../../components/common';

const AdminWarrantyDashboard = ({ user, UpdateUserAdminSetting }) => {

	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [list, setList] = useState([]);

	useEffect(() => {
		setData({
			dashboardSettingWarranty: user.dashboardSettingWarranty || 1,
		})
	}, [user]);

	useEffect(async () => {
		const aggregate = AggrWarrantyDashBoard(data.dashboardSettingWarranty, false);
		const ret = await CommonAction.GetCountAction(aggregate);
		setList(ret.data);
	}, [data]);


	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}

	return (
		<>
			<div className="extraHeader pb-0">
				<h2 className="mb-0">{t('warrantydashboard')}</h2>
				<div className="ray-right">
					{
						(user.adminType == 'S' && user.companyId != 'RAY02091' && user.companyId == "RAY00001") && <Link to="/Admin/AdminTenYears" className="btn btn-sm mt-0">
							<ion-icon name="share-outline"></ion-icon> {t('10 Years Warranty')}
						</Link>
					}
				</div>
			</div>
			<div id="appCapsule" className="extra-header-active full-height">
				<div className='section dashboardTop pt-2'>
					<div className='col'>
						<WidgetItemCountCard
							title={t('warranty')}
							subtitle={`${data.dashboardSettingWarranty} ${data.dashboardSettingWarranty > 1 ? t('months') : t('month')}`}
							aggregate={AggrWarrantyDashBoard(data.dashboardSettingWarranty, true)}
							setting={() => openSettingPopup('dashboardSettingWarranty', `${t('warranty')} (${t('month')})`)}
						/>
					</div>
				</div>
				<div className="section m-1 p-2" style={{ backgroundColor: '#fff' }}>
					<RayTable
						data={list}
						columns={[
							{ name: "company.country", label: "", type: "COUNTRY", showtype: "ONLYFLAG", style: { width: 24 } },
							{ name: "company.name", label: t('operator') },
							{ name: "sn", label: t('sn') },
							{ name: "model", label: t('model') },
							{ name: "option", label: t('option') },
							{ name: 'sites.sitename', label: t('site'), link: '/Admin/AdminSiteInfo', linkdata: 'sites.siteId' },
							{ name: 'pw', label: t('warrantyexpiredproduct'), type: 'DTONLY' },
							{ name: 'mw', label: t('warrantyexpiredmainparts'), type: 'DTONLY' },
							{ name: "installedDate", label: t('installeddate'), type: 'DTONLY' },
							{ name: 'shipmentdate', label: t('shipmentdate'), type: 'DTONLY' }
						]}
						options={{
							search: false,
							keyCol: '_id',
						}} />

				</div>
			</div>

			<Modal show={showModal} fullscreen={"true"} onHide={() => setShowModal(false)}>
				<Modal.Header>
					<Modal.Title>{t('settings')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='p-1'>
						<InputBasicNumber
							options={{
								label: selected.title,
								showpin: true,
								val: selected.value,
								positive: true,
							}}
							editable={true}
							onchange={handleChange}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
					<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	return { user };
};

const mapDispatch = (dispatch) => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
});

export default connect(mapState, mapDispatch)(AdminWarrantyDashboard);
