import { CountryCode } from "./CountryCode";
import { LanguageCode } from "./LanguageCode";
import { TimeZoneCode } from "./TimeZoneCode";
import { CompanyTypes, DeviceModels } from "./RayCodes";
import Sitemap from "./Sitemap";
import { TopUserMenuItems, HomeQuickMenuItems, LeftMenuItems } from "./MenuItems";
import { InstallationGuideList } from "./RayGuideCodes";

const JWT = () => {
    return {
        headers: {
            token: localStorage.getItem("userToken"),
            'x-ray-clist': localStorage.getItem("clist"),
        }
    }
}

const RT_TOKEN = () => {
    return {
        headers: {
            token: localStorage.getItem("rtToken")
        }
    }
}

const CheckEnv = () => {
    return 'development';
    return location.origin.toLowerCase().indexOf("raydevelop") > -1 ? "development" : "production";
}

const AppConfig = {
    API_SERVER_PATH: CheckEnv() === 'production' ? "https://api.raymedical.com/api" : "https://3jwja9uhsf.execute-api.ap-northeast-2.amazonaws.com/production",

    USER_API_PATH : CheckEnv() === 'production' ? 'https://api-manager-user.rayteams.com' : 'https://api-manager-user.raydevelop.com',
    GROUP_API_PATH : CheckEnv() === 'production' ? 'https://api-ap-northeast-2-group.rayteams.com' : 'https://api-ap-northeast-2-group.raydevelop.com',

    S3_ID: "AKIAV6JBTAHFFZXQPCK5",
    S3_KEY: "GAbG5nO8LWNP30woZEOQY+o5iqDISO4wELyPtWv/",
    S3_BUCKET: "ray-data",
    S3_REGION: "ap-northeast-2"
}

const Branch = ['RAY02091', 'RAY00010', 'RAY00013', 'RAY00015', 'RAY00100', 'RAY00016', 'RAY02078', 'RAY02108', 'RAY02123', 'RAY00012'];

const UserType = (companyId) => {
    if (companyId === 'RAY00001')
        return 'HQ';
    else if (Branch.includes(companyId))
        return 'BRANCH';
    else
        return 'DEALER';
}

const RAYGTTestMode = false;

export {
    Sitemap,
    TopUserMenuItems,
    HomeQuickMenuItems,
    LeftMenuItems,
    AppConfig,
    JWT,
    RT_TOKEN,
    CountryCode,
    LanguageCode,
    CompanyTypes,
    DeviceModels,
    TimeZoneCode,
    InstallationGuideList,
    // CheckEnv,
    RAYGTTestMode,
    Branch,
    UserType
}
