import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux'
import ProductCard from '../../components/product/ProductCard';
import SiteCard from '../../components/site/SiteCard';
import { ProductAction, SiteAction, ErrorCodeAction, ServiceAction } from '../../actions';
import { history } from '../../history';
import ServiceList from '../../components/service/ServiceList';
import ProductList from '../../components/product/ProductList';
import SiteList from '../../components/site/SiteList';
import ErrorCodeView from '../../components/errorCode/ErrorCodeView';
import ProductErrorList from '../../components/product/ProductErrorList';
import { InputSelect2 } from '../../components/common/Inputs';
import { DeviceModels } from '../../config';
import { ProgramConstant } from '../../actions/constants/ProgramConstant';
import ServiceCard from '../../components/service/ServiceCard';
import { Translate as t } from '../../config/Translate';
import { RayTable, ErrorCodeModelListHeader, ErrorCodeListHeader } from '../../components/common';

var mysearchcallCount = 4;
const MySearch = ({ searchFilters, showfav, title, titleCount }) => {

	const dispatch = useDispatch();
	const user = useSelector((state) => state.AuthReducer.user)
	const { view } = useParams();
	const [tab, setTab] = useState(view || "product");
	const [search, setSearch] = useState(searchFilters.search || "");
	const [searchView, setSearchView] = useState(false);
	const [product, setProduct] = useState([]);
	const [sites, setSites] = useState([]);
	const [services, setServices] = useState([]);
	const [errorCode, setErrorCode] = useState({});
	const [errorCodeModel, setErrorCodeModel] = useState({});
	const [isFav, setIsFav] = useState(showfav);
	const [model, setModel] = useState(searchFilters.model || []);
	const [hideP, setHideP] = useState(false);
	const [hideS, setHideS] = useState(false);
	const [hideSS, setHideSS] = useState(false);
	const [models, setModels] = useState([]);
	const [loadingProducts, setLoadingProducts] = useState(false);
		
	useEffect(() => {
		if (searchFilters) {
			setSearch(searchFilters.search);
			setModel(searchFilters.model);
			searchStart();
		}
	}, [searchFilters])

	useEffect(() => {
		setTab(view || "product")
	}, [view]);

	useEffect(() => {
		if((sites && product) && (sites.length > 0 && product.length > 0)) {
			const siteProduct = sites.map(x => {
				return {
					...x,
					product: product.find(f => f.siteId === x._id),
					products: product
				}
			})
			setSites(siteProduct);
			setLoadingProducts(false);
		}
	}, [product])

	const searchStart = () => {
		if (searchFilters.search || searchFilters.model.length > 0) {
			setLoadingProducts(true);
			searchProduct();
			searchService();
			searchSite();
			searchErrorCode();
		} else {
			cancelSearch();
		}
		setSearchView(false);
	}

	const searchGo = (e) => {
		mysearchcallCount = 0;
		e && e.preventDefault();
		dispatch({ type: ProgramConstant.SETSEARCHFILTERS, filters: { search: search.trim(), model: model } })
		setProduct([]);
		setServices([]);
		setSites([]);
		setErrorCode({});
		setErrorCodeModel({});
	}

	const clearSearch = () => {
		dispatch({ type: ProgramConstant.SETSEARCHFILTERS, filters: { search: "", model: "" } })
		history.push("/MySearch");
	}

	const searchProduct = async () => {
		var filter = {};
		if (searchFilters.search && searchFilters.model.length > 0) {
			filter['match'] = { sn: { $regex: searchFilters.search.toUpperCase(), $options: 'si' }, model: { '$in': searchFilters.model } };
		} else {
			if (searchFilters.search) {
				//filter['match'] = { "site.sitename": { $regex: searchFilters.search, $options: 'si' } };
				filter['match'] = { sn : { $regex: searchFilters.search.toUpperCase(), $options: 'si' } };
			}
			if (searchFilters.model.length > 0) {
				filter['match'] = { model: { '$in': searchFilters.model } };
			}
		}

		if (Object.keys(filter).length > 0) {
			const searchData = {
				searchCols: ['sn', 'site.sitename'],
				searchKey: searchFilters.search,
				filter
			}
			//setSiteProduct(false);
			const ret = await ProductAction.GetProductDirect(searchData);
			console.log(ret.list);

			setProduct(ret.list);
		}
		mysearchcallCount++;
	}

	const searchService = async () => {
		//const ret = await ServiceAction.GetSearchServiceAll(['ticketKey', 'summary', 'sn', 'errorCode'], searchFilters.search);
		const ret = await ServiceAction.GetSearchServiceByAtlasSearch(searchFilters.search);
		if (ret.data.status == 'success') {
			setServices(ret.data.data);
		} else {
			setServices([]);
		}
		mysearchcallCount++;
	}

	const searchSite = async () => {
		const ret = await SiteAction.GetSearchSiteAll(['sitename', 'customerFirstName', 'customerLastName'], searchFilters.search);
		if (ret.data.status == 'success') {
			setSites(ret.data.data.list);
		} else {
			setSites([]);
		}
		mysearchcallCount++;
	}

	const searchErrorCode = async () => {
		const list = await ErrorCodeAction.GetOneOnlyErrorCode("", searchFilters.search);
		const ulist = [...new Set(list.flatMap(item => item.model))]
		setModels(ulist);
		if (list.length > 0) {
			var viewInfo = list.filter(x => x.lang == user.rguardSettingLanguage);
			if (viewInfo.length > 0) {
				setErrorCode(viewInfo[0]);
				if(ulist.length == 1) {
					setErrorCodeModel(viewInfo[0]);
				}
			} else {
				viewInfo = list.filter(x => x.lang == "eng");
				if (viewInfo.length > 0) {
					setErrorCode(viewInfo && viewInfo[0]);
				} else {
					setErrorCode(list[0]);
				}
			}
		} else {
			setErrorCode({
				solutions: [],
				attachments: [],
			});
		}
		mysearchcallCount++;
	};

	const searchErrorCodeModel = async (model) => {
		const list = await ErrorCodeAction.GetOneOnlyErrorCode(model, searchFilters.search);
		if (list.length > 0) {
			var viewInfo = list.filter(x => x.lang == user.rguardSettingLanguage).find(f => f.model.includes(model));
			if (viewInfo && viewInfo?.length == 1) {
				setErrorCodeModel(viewInfo);
			} else {
				viewInfo = list.filter(x => x.lang == "eng").find(f => f.model.includes(model));
				if (viewInfo?.length > 0) {
					setErrorCodeModel(viewInfo[0]);
				}
			}
			setErrorCodeModel(viewInfo)
		} else {
			setErrorCodeModel({});
		}
	};

	const setFav = (bl) => {
		setIsFav(bl)
		dispatch({ type: bl ? ProgramConstant.SHOW_FAV : ProgramConstant.SHOW_ALL })
	}

	const cancelSearch = () => {
		if (searchView) {
			setSearchView(false);
			setSearch("");
			setProduct([]);
			setSites([]);
			setErrorCode({});
		} else {
			setSearchView(true);
		}
	}

	const moveTab = (code) => {
		dispatch({
			type: ProgramConstant.SET_SEARCHSCREEN_TITLE,
			title: "Loading...",
			count: 0
		});
		history.push(code);
	}

	const tabList = [
		{ code: "product", title: t("product"), icon: "support_agent" },
		{ code: "site", title: t("site"), icon: "error_outline" },
		{ code: "service", title: t("service"), icon: "error_outline" },
		{ code: "error", title: t("error"), icon: "error_outline" },
	]

	return (
		<>
			<div className="appHeader no-border">
				<div className="left">
					<a onClick={() => history.goBack()} className="headerButton goBack">
						<ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
					</a>
					{((searchFilters.search || searchFilters.model.length > 0) || searchView) &&
						<div className="pageTitle">
							{t('search')}{search && " : " + search}
						</div>}
					{!((searchFilters.search || searchFilters.model.length > 0) || searchView) &&
						<div className="pageTitle">
							{title + " "}
							{titleCount > 0 && <div className="chip">
								<div className="chip-label">{titleCount > 0 ? titleCount.toLocaleString() : ""}</div>
							</div>}
						</div>}
				</div>
				<div className="right">
					{(!searchView && tab != "error" && !(searchFilters.search || searchFilters.model.length > 0)) &&
						<a onClick={(e) => setFav(!isFav)} className={isFav ? "headerButton text-danger" : "headerButton"}>
							<ion-icon name={isFav ? "heart" : "heart-outline"}></ion-icon>
						</a>}
					<a onClick={(e) => {
						setSearchView(!searchView);
						setSearch('');
					}
					} className="headerButton">
						<ion-icon name="search-outline"></ion-icon>
					</a>
				</div>
			</div>
			{searchView &&
				<div className="extraHeader2 sch p-2">
					<form className="search-form" onSubmit={(e) => searchGo(e)}>
						<div className="form-group searchbox">
							<input className="form-control fs-22"
								type="text"
								onChange={(e) => setSearch(e.target.value)}
								placeholder={t('search')}
								value={search} />
							<i className="input-icon">
								<ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
							</i>
						</div>
						<button className="btn btn-primary btn-block" onClick={(e) => searchGo(e)}>{t('search')}</button>
						<button className="btn btn-dark btn-block" onClick={() => clearSearch()}>{t('clear')}</button>
					</form>
				</div>
			}
			<div id="appCapsule" className={searchView ? "extra-header-active full-height" : "full-height"}>
				{(!(searchFilters.search || searchFilters.model.length > 0) && !searchView) &&
					<>
						<div className="section mb-2">
							<ul className="nav nav-tabs style2 mt-1 mb-1">
								{tabList.map((x, idx) =>
									<li className="nav-item" key={idx}>
										<button key={x.code}
											className={tab == x.code ? "nav-link active" : "nav-link"}
											onClick={() => moveTab("/MySearch/" + x.code)}>
											{x.title}
										</button>
									</li>
								)}
							</ul>
						</div>
						{tab == "error" && <div><ProductErrorList showFav={isFav} /></div>}
						<div className="section full">
							{tab == "product" && <div><ProductList showFav={isFav} /></div>}
							{tab == "site" && <div><SiteList showFav={isFav} /></div>}
							{tab == "service" && <div><ServiceList showFav={isFav} /></div>}
						</div>
					</>
				}
				{((searchFilters.search || searchFilters.model.length > 0) && !searchView) &&
					<>
						{errorCode.errorCode &&
							<div className="section mb-2">
								<div className="section-title">{t('errorcode')}</div>
								<div className="card">

								</div>
								<div className="card">
									<div className="card-header">{errorCode.errorCode}
										<InputSelect2
											options={{ type: "select2", name: "model", label: "", list: DeviceModels.filter(x => user?.isMegagen ? x.code == 'R2 Studio Q' :  models.includes(x.code)), val: models.length == 1 ? models[0] : errorCodeModel.model, isMulti: false }} editable={true} isForm={false}
											onchange={(e) => searchErrorCodeModel(e.target.value)} />
									</div>
									<div className="card-body">{errorCodeModel.errorCode}
										{errorCodeModel.errorCode && <ErrorCodeView errorCodeInfo={errorCodeModel} /> }
									</div>
								</div>
							</div>}
						{product.length > 0 &&
							<div className="section mb-2">
								<div className="card mt-2">
									<div className="card-header">
										<h4 className="mb-0" onClick={() => setHideP(!hideP)}>{t('product')}
											{hideP && <span className="small"> ({product.length.toLocaleString()})</span>}
											<div className="float-end ms-2">
												<ion-icon name={hideP ? "chevron-down-outline" : "chevron-up-outline"}></ion-icon>
											</div>
										</h4>
									</div>
									{!hideP && <ul className="listview link-listview">
										{product.map(x => <li key={x.sn}><ProductCard item={x} /></li>)}
									</ul>}
								</div>
							</div>
						}
						{services.length > 0 &&
							<div className="section mb-2">
								<div className="card">
									<div className="card-header">
										<h4 className="mb-0" onClick={() => setHideSS(!hideSS)}>{t('service')}
											{hideSS && <span className="small"> ({services.length.toLocaleString()})</span>}
											<div className="float-end ms-2">
												<ion-icon name={hideSS ? "chevron-down-outline" : "chevron-up-outline"}></ion-icon>
											</div>
										</h4>
									</div>
									{!hideSS && <ul className="listview link-listview">
										{services.map(x => <li className="border-bottom"><ServiceCard item={x} /></li>)}
									</ul>}
								</div>
							</div>
						}
						{sites.length > 0 &&
							<div className="section mb-2">
								<div className="card">
									<div className="card-header">
										<h4 className="mb-0" onClick={() => setHideS(!hideS)}>{t('site')}
											{hideS && <span className="small"> ({sites.length.toLocaleString()})</span>}
											<div className="float-end ms-2">
												<ion-icon name={hideS ? "chevron-down-outline" : "chevron-up-outline"}></ion-icon>
											</div>
										</h4>
									</div>
									{!hideS && <ul className="listview link-listview">
										{sites.map(x => <li className="border-bottom"><SiteCard item={x} loadingProduct={loadingProducts} /></li>)}
									</ul>}
								</div>
							</div>
						}
						{(!product.length && !services.length && !sites.length && !errorCode.errorCode) &&
							<div className="section mb-2 mt-1">
								<div className="card">
									<div className="card-body text-center">
										{mysearchcallCount < 4 ? t('Please wait...') : t('norecord')}
									</div>
								</div>
								{mysearchcallCount === 4 && <>
									<button className="btn btn-primary btn-block" onClick={(e) => setSearchView(true)}>{t('search')}</button>
									<button className="btn btn-dark btn-block" onClick={() => clearSearch()}>{t('clear')}</button>
								</>}
							</div>
						}
					</>
				}
			</div>
		</>
	)
}

const mapState = (state) => {
	const showfav = state.ProgramReducer.showfav;
	const title = state.ProgramReducer.searchScreenTitle;
	const titleCount = state.ProgramReducer.searchScreenTitleCount;
	const searchFilters = state.ProgramReducer.searchFilters || { search: "", model: [] };

	return { showfav, searchFilters, title, titleCount };
};

export default connect(mapState)(MySearch);