import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import WdigetItemCountCard from '../../widgets/WdigetItemCountCard';
import WidgetCustomTable from '../../widgets/WidgetCustomTable';
import { Modal } from 'react-bootstrap';
import { InputBasicNumber } from './Inputs';
import { Translate as t } from '../../config/Translate';

import {
	AggrProductCountAll,
	AggrProductNewInstalled,
	AggrErrorNotCloseCount,
	AggrErrorNotClose,
	AggrErrorPerModel,
	AggrServiceOpenCount,
	AggrModelPercentByNDays,
	AggrModelPercent2,
	AggrServiceCountByStatus,
	AggrProductCountry,
	AggrProductInstallationByCountryLastNdays
} from '../../config/Aggregates';

import { AuthAction } from '../../actions';

import moment from 'moment';

const Statistics = ({ user, deviceCategory, UpdateUserAdminSetting, showmodel }) => {
	const [data, setData] = useState({});
	const [selected, setSelected] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [taiwanSales, setTaiwanSales] = useState(false);

	useEffect(() => {
		setData({
			dashboardSettingMainNewInstalledTile: user.dashboardSettingMainNewInstalledTile || 7,
			dashboardSettingMainNewInstalledByModel: user.dashboardSettingMainNewInstalledByModel || 7,
			dashboardSettingMainNewInstalledByCountry: user.dashboardSettingMainNewInstalledByCountry || 7
		});
	}, [])

	useEffect(() => {
		let interval;
		interval = setInterval(() => {
			setData({
				dashboardSettingMainNewInstalledTile: user.dashboardSettingMainNewInstalledTile || 7,
				dashboardSettingMainNewInstalledByModel: user.dashboardSettingMainNewInstalledByModel || 7,
				dashboardSettingMainNewInstalledByCountry: user.dashboardSettingMainNewInstalledByCountry || 7
			})
			console.log(moment(new Date().getTime()).format());
		}, 1000 * 60 * 10); // 10min

		setTaiwanSales(user.companyId === 'RAY00016' && user.adminType === 'U')

		return () => {
			clearInterval(interval);
		}
	}, [user]);

	const openSettingPopup = (key, title) => {
		setSelected({ key, title, value: data[key] });
		setShowModal(true);
	}

	const updateDashboardSetting = () => {
		UpdateUserAdminSetting({ [selected.key]: selected.value });
		setShowModal(false);
	}

	const handleChange = (e) => {
		setSelected({ ...selected, value: e.target.value });
	}

	const initializeUpdateData = () => {
		setShowModal(false);
	}

	return (
		<>
			{/* 상단카드 */}
			<div className="section dashboardTop">
				<div className="row">
					<div className="col">
						<WdigetItemCountCard
							title={t('product')}
							subtitle={t('all')}
							aggregate={AggrProductCountAll(deviceCategory)}
						/>
					</div>
					<div className="col">
						<WdigetItemCountCard
							title={t('newinstalled')}
							subtitle={`${data.dashboardSettingMainNewInstalledTile} ${t('days')}`}
							aggregate={AggrProductNewInstalled(deviceCategory, data.dashboardSettingMainNewInstalledTile)}
							setting={() => openSettingPopup('dashboardSettingMainNewInstalledTile', `${t('newinstalled')} (${t('days')})`)}
						/>
					</div>
					<div className="col">
						<WdigetItemCountCard
							// name='errorCount'
							title={t('errors')}
							subtitle="Open"
							aggregate={AggrErrorNotCloseCount(deviceCategory)} />
					</div>
					<div className="col">
						<WdigetItemCountCard
							// name='serviceCount'
							title={t('service')}
							subtitle="Open"
							aggregate={AggrServiceOpenCount(deviceCategory)} />
					</div>
				</div>
			</div>

			<div className="section mt-2">
				{/* 첫쨰줄 */}
				<div className="row">
					<div className="col">
						<WidgetCustomTable
							title={t('model')}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrModelPercent2(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('model')} (${t('newinstalled')})`}
							headers={[
								{ name: "_id.label", label: t('model') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrModelPercentByNDays(deviceCategory, data.dashboardSettingMainNewInstalledByModel)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingMainNewInstalledByModel', `${t('model')} (${t('newinstalled')}) (${t('days')})`)}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('errors')} (Open)`}
							basePath='/Admin/AdminErrorCodeInfo/'
							appendix={user?.isMegagen ? '/kor' : '/eng'}
							linkKey={!taiwanSales ? '_id.label' : ''}
							headers={[
								{ name: "_id.label", label: t('errors'), },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrErrorNotClose(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('status')} (Open)`}
							headers={[
								{ name: "_id", label: t('status'), type: "STATUSTYPE" },
								//{ name: "_id", label: t('status') },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrServiceCountByStatus(deviceCategory)}
							height={250}
						/>
					</div>
				</div>

				{/* 둘째줄 */}
				<div className="row">
					<div className="col">
						<WidgetCustomTable
							title={`${t('totalproductbycountry')}`}
							headers={[
								{ name: "_id.label", label: t('country'), type: "COUNTRY", showType: '' },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrProductCountry(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('newinstallationsbycountry')}`}
							headers={[
								{ name: "_id.label", label: t('country'), type: "COUNTRY", showType: '' },
								{ name: "value", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "value", style: { minWidth: 200 } },
							]}
							aggregate={AggrProductInstallationByCountryLastNdays(deviceCategory, data.dashboardSettingMainNewInstalledByCountry)}
							height={250}
							setting={() => openSettingPopup('dashboardSettingMainNewInstalledByCountry', `${t('newinstallationsbycountry')} (${t('days')})`)}
						/>
					</div>

					<div className="col">
						<WidgetCustomTable
							title={`${t('errorsbymodel')} (Open)`}
							headers={[
								{ name: "model", label: t('model') },
								{ name: "count", label: "Count", type: "NUMBER" },
								{ name: "", label: "", type: "PROGRESS", value: "count", style: { minWidth: 200 } },
							]}
							aggregate={AggrErrorPerModel(deviceCategory)}
							height={250}
						/>
					</div>

					<div className="col">
						<Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
							<Modal.Header>
								<Modal.Title>{t('settings')}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className='p-1'>
									<InputBasicNumber
										options={{
											label: selected.title,
											showpin: true,
											val: selected.value,
										}}
										editable={true}
										onchange={handleChange}
									/>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<button className="btn btn-block btn-primary mb-0 mt-0" onClick={() => updateDashboardSetting()}>{t('save')}</button>
								<button className="btn btn-block btn-dark" onClick={() => initializeUpdateData()}>{t('close')}</button>
							</Modal.Footer>
						</Modal>
					</div>
				</div>
			</div>
		</>
	)
};

const mapState = (state) => {
	var user = state.AuthReducer.user;
	var deviceCategory = state.ProgramReducer.deviceCategory;
	const showmodel = state.ProgramReducer.showmodel;
	return { user, deviceCategory, showmodel };
}

const mapDisPatch = dispatch => ({
	UpdateUserAdminSetting: (userInfo) => dispatch(AuthAction.UpdateUserAdminSetting(userInfo)),
});

export default connect(mapState, mapDisPatch)(Statistics);
