import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RayTable } from '../../components/common';
import { connect } from 'react-redux';
import { ProductAction } from '../../actions';
import moment from 'moment';
import { Translate as t } from '../../config/Translate';

const AdminTenYears = ({ BulkUpdateEachProductTenYearsWarranty, products, companies, clist, isLoading }) => {
	const [view, setView] = useState("raw");
	const [rawData, setRawData] = useState("");
	const [list, setList] = useState([]);
	const [col, setCols] = useState([]);
	const [codeList, setCodeList] = useState([]);
	const [subtitle, setSubTitle] = useState("");
	const [isProcess, setisProcess] = useState(false);
	const [appliable, setAppliable] = useState(false);
	const [reason, setReason] = useState("");
	const [times, setTimes] = useState([]);

	const updateColumnName = "S/N";
	const updateColumnNameShip = "SN";

	const options = {
		filterColumns: []
	}

	useEffect(() => {
		setCodes();
	}, [clist]);

	useEffect(() => {
		if (isProcess) {
			setView("done");
		}
	}, [products])

    useEffect(() => {
        if(!isLoading && isProcess) {
            setView("done");
        }
    }, [isLoading])

	useEffect(() => {
		if (codeList.length > 0)
			setTimes(codeList.filter(x => x.datatype == "DATETIME").map(y => y.code));
	}, [codeList])


	const setCodes = () => {
		console.log('AdminProductImport -> ', clist?.filter(x => x.type === 'PRODUCTEXTRAKEY'));
		var list = clist?.filter(x => x.type === 'PRODUCTEXTRAKEY');
		list.push({ code: 'installedDate', name: "Installeddate", alias: ["설치일"] });
		list.push({ code: "shipmentdate", name: "Shipmentdate", alias: ["선적일"] });
		setCodeList(list);
	}

	const process = () => {
		setisProcess(true);
		setView("process");
		var plist = [...list];
		plist.map(x => {
			times.map(t => {
				if (x.hasOwnProperty(t)) {
					x[t] = moment(x[t]).valueOf()
				}
			})
			return x;
		})

		plist = makeDataforUpdate(plist);
		BulkUpdateEachProductTenYearsWarranty(plist);
	}

	const makeDataforUpdate = (plist) => {
		var tmpList = [];
		plist.map(x => {
			var obj = [];
			obj.push(x.sn);// = x.sn;
			tmpList.push(x.sn);
		})
		return tmpList;
	}

	const goPreview = () => {
		setView("convert");
		var lines = rawData.split('\n');
		if (lines.length > 0) {
            manParser();
		}
	};

	const setObject = (header, type) => {
		var columnHeaders = [];

		header.split('\t').map((x, idx) => {
			if (x.trim().toUpperCase() == (type == "MAN" ? updateColumnName : updateColumnNameShip)) {
				columnHeaders.push({
					code: (type == "MAN" ? updateColumnName : updateColumnNameShip),
					idx: idx,
				})
			} else {
				var n = codeList.filter(y =>
					y.name.toUpperCase() == x.trim().toUpperCase() ||
					y.alias.map(m => m.toUpperCase()).includes(x.trim().toUpperCase()));
				if (n.length > 0) {
					columnHeaders.push({
						code: n[0].code,
						idx: idx,
					})
				}
			}
		})
		return columnHeaders;
	}

	const setLine = (headers, item, type) => {
		var tp = (type == "MAN" ? updateColumnName : updateColumnNameShip)
		var obj = {};
		var arr = item.split('\t');
		headers.map((x, idx) => {
			var n = arr.filter((y, hidx) => x.idx == hidx);
			if (n.length > 0) {
				if (arr[x.idx]) {
					if (x.code == tp) {
						if (arr[x.idx] != "")
							obj[x.code == tp ? "sn" : x.code] = arr[x.idx];
					} else {
						obj[x.code == tp ? "sn" : x.code] = arr[x.idx];
					}
				}
			}
		});
		return obj;
	}

	const manParser = () => {
		var nlist = []
		var lines = rawData.split('\n');
		const headers = setObject(lines[0], "MAN");
		const obj = {};
		headers.forEach(x => obj[x.code === "S/N" ? "sn" : x.code] = "");
		setCols(makeCol(obj));
		lines.map((x, idx) => {
			if (idx > 0 && x.trim() != "")
				nlist.push(setLine(headers, x, "MAN"))
		});
		setAppliable(true);
		setList(nlist);
	}

	var titleCase = s => s
		.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
		.replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);

	const makeCol = (item) => {
		var nlist = [];
		Object.keys(item).map(y => {
			var keyname = y;
			if (typeof item[y] === "object") {
				Object.keys(item[y]).map(n => {
					nlist.push({ name: y + "." + n, label: titleCase(y) + " " + titleCase(n), type: times.includes(y + "." + n) ? "DT" : "" });
				})
			} else {
				if (keyname == "companyId") {
					nlist.push({
						name: keyname, 
						// label: titleCase(keyname),
						label: codeList.find(x => x.code === keyname)?.name,
						options: {
							title: "Assign",
							paramType: 'callback'
						},
						type: "BUTTON"
					});
				} else {
					nlist.push({
						name: keyname,
						// label: titleCase(keyname),
						label: keyname === "sn" ? "S/N" : codeList.find(x => x.code === keyname)?.name,
						type: times.includes(keyname) ? "DT" : ""
					});
				}
			}
		});

		return nlist;
	};

	const callBackData = (data) => {
		setItem(data);
		setShowModal(true);
	}

	return (
		<>
			{
				<>
					<div className="extraHeader">
						<h2 className="mb-0">{t('10 Years Warranty')}</h2>
						<div className="ray-right">
						</div>
					</div>
					<div id="appCapsule" className="extra-header-active full-height">
						{view == "raw" && <>
							<div className="section mt-1 mb-1">
								<div className="section-title">
									{t('writerawdata')}
									<div className="float-end">
										{rawData != "" && <button
											onClick={() => goPreview()}
											className="btn btn-primary">Convert</button>}
									</div>
								</div>
								<div>
									<textarea className="form-control" rows="20"
										autoComplete="off" style={{ height: 400 }}
										defaultValue={rawData}
										onChange={(e) => setRawData(e.target.value)}></textarea>
								</div>
							</div>
						</>}
						{view == "convert" && <>
							<div className="section mt-1 mb-1">
								<div className="section-title">
									Preview Data - {subtitle}{reason && <> <span className="alert alert-warning">{reason}</span></>}
									<div className="float-end">
										<button className="btn btn-secondary me-1" onClick={() => setView("raw")}>{t('cancel')}</button>
										<button className={appliable ? "btn btn-primary" : "btn btn-primary disabled"} onClick={() => process()}>{t('apply')}</button>
									</div>
									<div className="clearfix" />
								</div>
							</div>
							<div className="card section mt-1 mb-1 p-3">
								<RayTable
									css="small table table-bordered mb-1"
									calllback={(data) => callBackData(data)}
									onlytable={true}
									data={list}
									columns={col}
									options={options} />
							</div>
						</>}
						{view == "process" && <>
							<div className="section mt-1 mb-1">
								<div className="card">
									<div className="card-body text-center" style={{ padding: 60 }}>
										<h3>Please wait a minute...</h3>
									</div>
								</div>
							</div>
						</>}
						{view == "done" && <>
							<div className="section mt-1 mb-1">
								<div className="section mt-1 mb-1">
									<div className="card">
										<div className="card-body text-center" style={{ padding: 60 }}>
											<h3>All updates are complete!</h3>
											<Link to="/Admin/AdminProductList" className="btn btn-primary">Product List</Link>
										</div>
									</div>
								</div>
							</div>
						</>}
					</div>
				</>
			}
		</>
	);
};

const mapState = (state) => {
	return {
		companies: state.CompanyReducer.companies,
		codelist: state.RayCodeReducer.items,
		products: state.ProductReducer.products,
		clist : state.RayCodeReducer.items,
        isLoading: state.ProductReducer.isLoading,
	};
};

const mapDispatch = (dispatch) => ({
    BulkUpdateEachProductTenYearsWarranty: (list) => dispatch(ProductAction.BulkUpdateEachProductTenYearsWarranty(list)),
});

export default connect(mapState, mapDispatch)(AdminTenYears);
